import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Index from "../../container/user/learner/index/Index";
import LearnerProfile from "../../container/user/learner/profile";
import CompanyTraningProcess from "../../container/user/learner/company/traningProcess";
import CompanyWorkSpace from "../../container/user/learner/company/worksSpace/course/Index";
import LearnedCourseSections from "../../container/user/learner/company/worksSpace/learned/section/Index";
import NotLearnedCourseSections from "../../container/user/learner/company/worksSpace/notLearned/section/Index";
import LearnedCourseVideos from "../../container/user/learner/company/worksSpace/learned/videos/Index";
import NotLearnedCourseVideos from "../../container/user/learner/company/worksSpace/notLearned/videos/Index";

// DEFINE MAIN FUNCTION
const LearnerPages = ({ width }) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <Routes>
      <Route exact path={`/`} element={<Index width={width} />} />
      <Route exact path={`/profile`} element={<LearnerProfile />} />
      <Route exact path={`/company/workSpace`} element={<CompanyWorkSpace />} />
      <Route
        exact
        path={`/company/workSpace/learned/:id`}
        element={<LearnedCourseSections />}
      />
      <Route
        exact
        path={`/company/workSpace/notLearned/:id`}
        element={<NotLearnedCourseSections />}
      />
      <Route
        exact
        path={`/company/workSpace/notLearned/:id/:id`}
        element={<NotLearnedCourseVideos />}
      />
      <Route
        exact
        path={`/company/workSpace/learned/:id/:id`}
        element={<LearnedCourseVideos width={width} />}
      />
      <Route
        exact
        path={`/company/traningProcess`}
        element={<CompanyTraningProcess />}
      />
      <Route exact path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default LearnerPages;
