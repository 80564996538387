import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Avatar,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  Pagination,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  memberCreate,
  memberEdit,
  memberDelete,
  memberList,
} from "../../redux/admin/members/action";
import {
  capitalizeFirstLetter,
  onUploadImageValidation,
} from "../../config/CommonFuntion";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import { organizationList } from "../../redux/admin/organization/action";

// DEFINE MAIN FUNCTION
const Member = () => {
  // DEFINE ALL STATES
  const { Title } = Typography;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uploadImageRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [memberModal, setMemberModal] = useState(false);
  const [memberDeleteModal, setMemberDeleteModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRole, setSelectedRole] = useState("all");
  const [modalLoading, setModalLoading] = useState(false);
  const [memberModalType, setMemberModalType] = useState("");
  const [selectedorganization, setSelectedorganization] = useState("");

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      list(
        currentPage,
        pageSize,
        searchData,
        selectedorganization,
        selectedRole
      );
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT FOR ORGANIZATION DETAILS
  useEffect(() => {
    organizationDataList();
  }, []);

  // DEFINE FUNCTION FOR ORGANIZATION DETAILS
  const organizationDataList = async () => {
    await dispatch(organizationList("", "", ""));
  };

  // DEFINE ALL LISTS WITH REDUX
  let listOfMember = useSelector((state) => state.member.list);
  let countsOfMember = useSelector((state) => state.member.listCounts);
  let listOfOrganization = useSelector(
    (state) => state.organization.filterList
  );

  // DEFINE FUNCTION FOR ROLE CHANGE
  const onRoleChange = (e) => {
    setCurrentPage(1);
    setPageSize(10);
    setSearchData("");
    list(1, 10, searchData, selectedorganization, e.target.value);
    setSelectedRole(e.target.value);
    setLoading(true);
  };

  // DEFINE FUNCTION FOR USER TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    setLoading(true);
    list(page, current, searchData, selectedorganization, selectedRole);
  };

  // DEFINE FUNCTION FOR USER LIST
  const list = async (page, current, search, selectedorganization, role) => {
    await dispatch(
      memberList(
        page,
        current,
        search,
        selectedorganization,
        role == "all" ? "" : role
      )
    );
    setModalLoading(false);
    setLoading(false);
  };

  // DEFINE CREATE AND UPDATE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setModalLoading(false);
    }
  };

  // USER FORM SUBMIT FUNCTION DEFINE
  const onMemberFormSubmit = async (values) => {
    setModalLoading(true);
    let formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("position", values.position);
    if (memberModalType == "Create") {
      formData.append("organization", values.organization);
      formData.append("role", values.role);
      formData.append("email", values.email);
      formData.append("profilePic", uploadedFile);
    } else {
      if (uploadedFile !== selectedData.profilePic) {
        formData.append("profilePic", uploadedFile);
      }
    }
    if (memberModalType == "Edit") {
      let result = await dispatch(
        memberEdit(formData, selectedData._id, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        list(
          currentPage,
          pageSize,
          searchData,
          selectedorganization,
          selectedRole
        );
      } else {
        setModalLoading(false);
      }
    } else {
      let result = await dispatch(memberCreate(formData, loadingPercentage));
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        list(
          currentPage,
          pageSize,
          searchData,
          selectedorganization,
          selectedRole
        );
      } else {
        setModalLoading(false);
      }
    }
  };
  const deleteMember = async () => {
    let result = await dispatch(memberDelete(selectedData._id));
    if (result) {
      setMemberDeleteModal(false);
      setMemberModalType("");
      setSelectedData(null);
      list(
        currentPage,
        pageSize,
        searchData,
        selectedorganization,
        selectedRole
      );
    } else {
      console.log("Error");
    }
  };
  // USER TABLE COLUMNS DATA
  const userTableColumns = [
    {
      title: t("Name"),
      dataIndex: "firstName",
      key: "name",
      width: "33%",
      render: (icon, value) => {
        return (
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              src={
                value.profilePic == ""
                  ? "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  : value.profilePic
              }
              size={25}
              alt=""
            />
            <div className="avatar-info">
              <Title level={5}>
                {value.firstName} {value.lastName}
              </Title>
              <p>{value.email}</p>
            </div>
          </Avatar.Group>
        );
      },
    },
    {
      title: t("Organization Name"),
      key: "organization",
      dataIndex: "organization",
      width: "20%",
      render: (icon, value) => {
        return (
          <div>
            <span>
              {value?.organization?.name ? value?.organization?.name : "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: t("Total Course"),
      key: "courseCount",
      dataIndex: "courseCount",
      width: "10%",
      render: (icon, value) => {
        return (
          <div>
            <span>{value?.courseCount ? value?.courseCount : "0"}</span>
          </div>
        );
      },
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      width: "15%",
      render: (icon, value) => {
        return (
          <div className="author-info">
            <Title level={5}>{capitalizeFirstLetter(value.role)}</Title>
            <p>{value.position ? capitalizeFirstLetter(value.position) : ""}</p>
          </div>
        );
      },
    },
    {
      title: t("Created"),
      key: "employed",
      dataIndex: "employed",
      width: "13%",
      render: (icon, value) => {
        return (
          <div>
            <span>{moment(value.createdAt).format("DD-MM-YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "action",
      width: "9%",
      render: (icon, value) => {
        return (
          <>
            <div
              className="ant-employed"
              style={{ justifyContent: "space-evenly" }}
            >
              <FeatherIcon
                icon={"edit"}
                onClick={() => {
                  setMemberModal(true);
                  setMemberModalType("Edit");
                  setSelectedData(value);
                  setUploadedFile(
                    value.profilePic == ""
                      ? "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      : value.profilePic
                  );
                  form.setFieldsValue({
                    firstName: value.firstName,
                    lastName: value.lastName,
                    position: value.position,
                    profilePic: value.profilePic,
                    organization: value.organization,
                    role: value.role,
                    email: value.email,
                    organization: value?.organization?._id,
                  });
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/members/${value._id}`, {
                    replace: true,
                  });
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
              {value && value?.role == "learner" ? (
                <FeatherIcon
                  icon={"trash"}
                  onClick={() => {
                    setMemberDeleteModal(true);
                    setMemberModalType("Delete");
                    setSelectedData(value);
                  }}
                  style={{ cursor: "pointer" }}
                  color="rgb(11, 35, 34)"
                  size={22}
                />
              ) : (
                ""
              )}
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadedFile(e.target.files[0]);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR USER CREATE & EDIT */}
      <Modal
        title={`${t(memberModalType)} ${t("Member")}`}
        centered
        open={memberModal}
        okText={t("Save")}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!modalLoading) {
            form.resetFields();
            setMemberModal(false);
            setMemberModalType("");
            setUploadedFile(null);
            setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onMemberFormSubmit}>
          <Row gutter={16}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("First Name")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member first name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter member first name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Last Name")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member last name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter member last name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="image" label={t("Image")}>
                <div className="orgUploadedIMG">
                  <div
                    style={{
                      width: uploadedFile ? "140px" : "100%",
                      fontSize: uploadedFile ? "12px" : "16px",
                    }}
                    onClick={() => {
                      uploadImageRef.current.click();
                    }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: uploadedFile ? "16px" : "20px",
                      }}
                    />{" "}
                    {t("Click to Upload")}
                  </div>
                  {uploadedFile ? (
                    <>
                      {" "}
                      <img
                        src={
                          typeof uploadedFile == "string"
                            ? uploadedFile
                            : URL.createObjectURL(uploadedFile)
                        }
                      />
                      <label
                        onClick={() => {
                          setUploadedFile(null);
                          form.resetFields(["image"]);
                        }}
                      >
                        x
                      </label>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={uploadImageRef}
                  style={{ display: "none" }}
                  onChange={onUploadImageChange.bind(this)}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Role")}
                name="role"
                rules={[
                  {
                    required: true,
                    message: t("Please select member role!"),
                  },
                ]}
                className="memberRole"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={t("Select member role")}
                  style={{ height: "45px" }}
                  disabled={memberModalType == "Edit" ? true : false}
                  options={[
                    {
                      value: "learner",
                      label: t("Learner"),
                    },
                    {
                      value: "trainer",
                      label: t("Administrator"),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t("Email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("Please enter member email!"),
              },
              {
                type: "email",
                message: t("Please enter a valid email!"),
              },
            ]}
          >
            <Input
              placeholder={t("Enter member email")}
              disabled={memberModalType == "Edit" ? true : false}
              style={{
                height: "45px",
                color: "#8c8c8c",
                backgroundColor: "white",
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("Organization")}
            name="organization"
            rules={[
              {
                required: true,
                message: t("Please select organization!"),
              },
            ]}
            className="memberRole"
          >
            <Select
              showSearch
              disabled={memberModalType == "Edit" ? true : false}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("Select member organization")}
              style={{ height: "45px" }}
              options={listOfOrganization}
            />
          </Form.Item>

          <Form.Item label={t("Position")} name="position">
            <Input
              placeholder={t("Member current position")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        open={memberDeleteModal}
        okText={t("Delete")}
        onOk={() => {
          deleteMember();
        }}
        onCancel={() => {
          setMemberDeleteModal(false);
        }}
      >
        <Row gutter={16}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div
              className="d-flex flex-column text-center"
              style={{ marginTop: "30px" }}
            >
              <FeatherIcon
                icon={"alert-circle"}
                style={{ cursor: "pointer" }}
                color="#FCA12F"
                size={80}
              />
              <h2>{t("Are you sure you want to delete?")}</h2>
            </div>
          </Col>
        </Row>
      </Modal>
      {/* DEFINE USER LIST TABLE COMPONENT */}
      <div className="tabled">
        <Row gutter={25}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 memberCard "
              title={t("Members List")}
              extra={
                <>
                  {" "}
                  <Input
                    addonBefore={<SearchOutlined />}
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    style={{ height: "40px", width: "250px" }}
                    placeholder={t("search courses")}
                  />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder={t("select member organization")}
                    defaultValue={""}
                    style={{ height: "40px", width: "250px" }}
                    onChange={(value) => {
                      setLoading(true);
                      list(1, 10, "", value, selectedRole);
                      setSelectedorganization(value);
                    }}
                    options={
                      listOfOrganization.length > 0
                        ? [{ label: "All", value: "" }, ...listOfOrganization]
                        : listOfOrganization
                    }
                  />
                  <Radio.Group onChange={onRoleChange} defaultValue="all">
                    <Radio.Button value="all">{t("All")}</Radio.Button>
                    <Radio.Button value="trainer">
                      {t("Administrator")}
                    </Radio.Button>
                    <Radio.Button value="learner">{t("Learner")}</Radio.Button>
                  </Radio.Group>{" "}
                  <button
                    className="add-btn"
                    onClick={() => {
                      setMemberModal(true);
                      setMemberModalType("Create");
                    }}
                  >
                    {t("Add")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={userTableColumns}
                  dataSource={listOfMember}
                  loading={loading}
                  pagination={false}
                  className="ant-border-space"
                  style={{
                    display: "block",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                  }}
                />
                <div className="tabelsPagenation">
                  <Pagination
                    total={countsOfMember.totalCount}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    defaultPageSize={pageSize}
                    defaultCurrent={currentPage}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Member;
