import { message } from "antd";
import { API } from "../../../../config/api/Index";
import { DataService } from "../../../../config/DataService";

// LOGIN API CALL
export const notLearningCourseList = (page, current, search) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.course.notLearningList}?page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.course.notLearningList,
          notLearningList: response.data.data,
          notLearningCourseListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// LOGIN API CALL
export const learningCourseList = (page, current, search) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.course.learningList}?page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.course.learningList,
          learningList: response.data.data,
          learningCourseListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// LOGIN API CALL
export const allCourseList = (page, current, search, organizationId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.learner.course.allList
        }?organizationId=${organizationId}&page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.course.allList,
          allCourseList: response.data.data,
          allCourseListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const allLearnerCourseList = (page, current, search, userId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.course.allList + userId}?page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.course.allList,
          allCourseList: response.data.data,
          allCourseListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const allAssignLearnerCourseList = (orgId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.course.allAssignList}?organizationId=${orgId}&pagination=false`
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.course.allAssignList,
        });
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const allAssignSubmit = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.learner.course.asignCourse,
        payload
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
// LOGIN API CALL
export const startLearning = (courseId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.learner.course.startLearning,
        {
          courseId: courseId,
        }
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
