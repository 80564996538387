import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  Pagination,
} from "antd";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationCreate,
  organizationEdit,
  organizationList,
} from "../../redux/admin/organization/action";
import {
  handleMobileNumberFormat,
  onUploadImageValidation,
} from "../../config/CommonFuntion";
import moment from "moment";

// DEFINE MAIN FUNCTION
const Organization = () => {
  // DEFINE ALL STATES
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const uploadImageRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberCode, setNumberCode] = useState("+91");
  const [selectedData, setSelectedData] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [registerModalType, setRegisterModalType] = useState("");

  // DEFINE USEEFFECT WITH ORGANIZATION SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT FOR IMAGE ONCHANGE CHECK IMAGE FIELD
  useEffect(() => {
    form.validateFields(["image"]);
  }, [uploadedFile]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfOrganization = useSelector((state) => state.organization.list);
  let countsOfOrganization = useSelector(
    (state) => state.organization.listCounts
  );

  // DEFINE FUNCTION FOR ORGANIZATION LIST
  const list = async (page, current, search) => {
    await dispatch(organizationList(page, current, search));
    setLoading(false);
  };

  // DEFINE FUNCTION FOR ORGANIZATION TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    setLoading(true);
    list(page, current, searchData);
  };

  // DEFINE CREATE AND UPDATE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setModalLoading(false);
    }
  };

  // ORGANIZATION FORM SUBMIT FUNCTION DEFINE
  const onFinish = async (values) => {
    setModalLoading(true);
    let formData = new FormData();
    if (registerModalType == "edit") {
      if (uploadedFile !== selectedData.image) {
        formData.append("image", uploadedFile);
      }
    } else {
      formData.append("image", uploadedFile);
    }
    formData.append("name", values.name);
    formData.append("phoneNumber", numberCode + " " + values.phoneNumber);
    formData.append("discription", values.discription);
    formData.append("address", values.address);
    if (registerModalType == "edit") {
      let result = await dispatch(
        organizationEdit(formData, selectedData._id, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setRegisterModal(false);
        form.resetFields();
        setRegisterModalType("");
        setNumberCode("+91");
        setPhoneNumber("");
        setSelectedData(null);
        setLoading(true);
        list(currentPage, pageSize, searchData);
      } else {
        setModalLoading(false);
      }
    } else {
      let result = await dispatch(
        organizationCreate(formData, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setRegisterModal(false);
        form.resetFields();
        setNumberCode("+91");
        setPhoneNumber("");
        setRegisterModalType("");
        setSelectedData(null);
        setLoading(true);
        list(currentPage, pageSize, searchData);
      } else {
        setModalLoading(false);
      }
    }
  };

  // ORGANIZATION TABLE COLUMNS DATA
  const organizationTableColumn = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "33%",
      render: (icon, value) => {
        return (
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              src={
                value.image == ""
                  ? "https://www.svgrepo.com/show/490660/company.svg"
                  : value.image
              }
              size={25}
              alt=""
            />
            <div className="avatar-info">
              <Title level={5}>{value.name}</Title>
            </div>
          </Avatar.Group>
        );
      },
    },
    {
      title: t("Course"),
      dataIndex: "courseCount",
      width: "10%",
    },
    {
      title: t("Learner"),
      dataIndex: "learnerCount",
      width: "10%",
    },
    {
      title: t("Administrator"),
      dataIndex: "trainerCount",
      width: "10%",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phoneNumber",
      width: "15%",
    },
    {
      title: t("Created"),
      key: "createdAt",
      dataIndex: "createdAt",
      width: "15%",
      render: (icon, value) => {
        return (
          <div>
            <span>{moment(value.createdAt).format("DD-MM-YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: t("Action"),
      width: "7%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <FeatherIcon
                icon={"edit"}
                onClick={() => {
                  setRegisterModal(true);
                  setRegisterModalType("edit");
                  setSelectedData(value);
                  setUploadedFile(value.image);
                  form.setFieldsValue({
                    phoneNumber: handleMobileNumberFormat(
                      value.phoneNumber.split(" ").join("").slice(3, 15),
                      value.phoneNumber.split(" ").join("").slice(0, 3)
                    ),
                    name: value.name,
                    discription: value.discription,
                    address: value.address,
                  });
                  setNumberCode(
                    value.phoneNumber.split(" ").join("").slice(0, 3)
                  );
                  setPhoneNumber(
                    value.phoneNumber.split(" ").join("").slice(3, 15)
                  );
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/organization/${value._id}`);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadedFile(e.target.files[0]);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE CREATE & EDIT ORGANIZATION MODAL */}
      <Modal
        title={`${
          registerModalType == "edit" ? t("Edit") : t("Create")
        } ${"Organization"}`}
        centered
        open={registerModal}
        okText={`${registerModalType == "edit" ? t("Edit") : t("Create")} ${t(
          "Organization"
        )}`}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!modalLoading) {
            setRegisterModal(false);
            form.resetFields();
            setRegisterModalType("");
            setUploadedFile(null);
            setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        {registerModalType == "edit" ? (
          <div className="registerModalData">
            <div className="image">
              {" "}
              <label>
                {selectedData.image == "" ? (
                  <FeatherIcon icon="box" color="white" size={36} />
                ) : (
                  searchData.image
                )}
              </label>
            </div>
            <div className="details">
              <label>{selectedData.name}</label>
              <label>
                {moment(selectedData.createdAt)
                  .fromNow()
                  .replace("ago", t("Experience"))}{" "}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={t("Name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("Please enter organization name!"),
              },
            ]}
          >
            <Input
              placeholder={t("Enter organization name")}
              style={{ height: "45px" }}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: uploadedFile ? false : true,
                    message: t("Please upload organization image!"),
                  },
                ]}
                label={t("Image")}
              >
                <div className="orgUploadedIMG">
                  <div
                    style={{
                      width: uploadedFile ? "140px" : "100%",
                      fontSize: uploadedFile ? "12px" : "16px",
                    }}
                    onClick={() => {
                      uploadImageRef.current.click();
                    }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: uploadedFile ? "16px" : "20px",
                      }}
                    />{" "}
                    {t("Click to Upload")}
                  </div>
                  {uploadedFile ? (
                    <>
                      {" "}
                      <img
                        src={
                          typeof uploadedFile == "string"
                            ? uploadedFile
                            : URL.createObjectURL(uploadedFile)
                        }
                      />
                      <label
                        onClick={() => {
                          setUploadedFile(null);
                          form.resetFields(["image"]);
                        }}
                      >
                        x
                      </label>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={uploadImageRef}
                  style={{ display: "none" }}
                  onChange={onUploadImageChange.bind(this)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("Mobile Number")}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: t("Please enter mobile number!"),
                  },
                  {
                    min: numberCode == "+91" ? 11 : 13,
                    message: `${t("Please enter a")} ${
                      numberCode == "+91" ? 10 : 9
                    } ${t("digit mobile number!")}`,
                  },
                ]}
              >
                <Input
                  placeholder={t("Mobile number!")}
                  onInput={(event) => {
                    const input = event.target.value.replace(/\D/g, "");
                    setPhoneNumber(input);
                    event.target.value = handleMobileNumberFormat(
                      input,
                      numberCode
                    );
                  }}
                  styles={{ input: { height: "45px" } }}
                  addonBefore={
                    <Select
                      defaultValue={numberCode}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ height: "45px" }}
                      onChange={(value) => {
                        setNumberCode(value);
                        form.setFieldValue(
                          "phoneNumber",
                          handleMobileNumberFormat(phoneNumber, value)
                        );
                      }}
                      options={[
                        {
                          value: "+91",
                          label: "+91",
                        },
                        {
                          value: "+33",
                          label: "+33",
                        },
                      ]}
                    />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t("Description")}
            name="discription"
            rules={[
              {
                required: true,
                message: t("Please enter organization discription!"),
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t("Enter organization discription")}
            />
          </Form.Item>
          <Form.Item
            label={t("Address")}
            name="address"
            rules={[
              {
                required: true,
                message: t("Please enter organization address!"),
              },
            ]}
          >
            <Input.TextArea
              rows={2}
              placeholder={t("Enter organization address")}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* DEFINE ORGANIZATION LISTING TABLE COMPONENT */}
      <div className="tabled organizationTableData">
        <Row gutter={25}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={t("Organization Data")}
              extra={
                <>
                  <Input
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder={t("Search Organizations")}
                  />
                  <button
                    className="add-btn"
                    onClick={() => {
                      setRegisterModal(true);
                      setRegisterModalType("create");
                    }}
                  >
                    {t("Add")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={organizationTableColumn}
                  loading={loading}
                  dataSource={listOfOrganization}
                  className="ant-border-space organizationTableList"
                  pagination={false}
                />

                <div className="tabelsPagenation">
                  <Pagination
                    total={countsOfOrganization.totalCount}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    defaultPageSize={pageSize}
                    defaultCurrent={currentPage}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Organization;
