import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../../container/auth/login/login";
import ForgotPassword from "../../container/auth/forgotPassword/forgotPassword";
import OtpVerify from "../../container/auth/otpVerify/otpVerify";
import ResetPassword from "../../container/auth/resetPassword/resetPassword";

// DEFINE MAIN FUNCTION
const PublicRoutes = ({ width }) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <Routes>
      <Route exact path={`/`} element={<Login />} />
      <Route exact path={`/forgot-password`} element={<ForgotPassword />} />
      <Route exact path={`/forgot-password/otp/:id`} element={<OtpVerify />} />
      <Route exact path={`/reset-password/:id`} element={<ResetPassword />} />
      <Route exact path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default PublicRoutes;
