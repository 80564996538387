import React from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { convertToBigValues } from "../../../config/CommonFuntion";

// DEFINE MAIN FUNCTION
const EChart = ({ data }) => {
  // DEFINE ALL STATES
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();

  // DEFINE E CHART DATA
  const eChartData = {
    series: [
      {
        name: t("Organisation"),
        data: data?.organisationGraph?.organisationTotalCount,
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: data?.organisationGraph?.month,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChartData.options}
          series={eChartData.series}
          type="bar"
          height={260}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>{t("Active Organisations")}</Title>
        <Paragraph className="lastweek"></Paragraph>
        <Row gutter>
          <Col xs={5} xl={5} sm={5} md={5}>
            <div className="chart-visitor-count">
              <Title level={4}>
                {convertToBigValues(data?.totalOrganizationsInLastMonth)}
              </Title>
              <span>{t("Organizations")}</span>
            </div>
          </Col>
          <Col xs={1} xl={1} sm={1} md={1}></Col>
          <Col xs={4} xl={4} sm={4} md={4}>
            <div className="chart-visitor-count">
              <Title level={4}>
                {convertToBigValues(data?.totalLearnersInLastMonth)}
              </Title>
              <span>{t("Learners")}</span>
            </div>
          </Col>
          <Col xs={4} xl={4} sm={4} md={4}>
            <div className="chart-visitor-count">
              <Title level={4}>
                {convertToBigValues(data?.totalVideosInLastMonth)}
              </Title>
              <span>{t("Videos")}</span>
            </div>
          </Col>
          <Col xs={4} xl={4} sm={4} md={4}>
            <div className="chart-visitor-count">
              <Title level={4}>
                {convertToBigValues(data?.totalViewsInLastMonth)}
              </Title>
              <span>{t("Views")}</span>
            </div>
          </Col>
          <Col xs={4} xl={4} sm={4} md={4}>
            <div className="chart-visitor-count">
              <Title level={4}>
                {convertToBigValues(data?.totalSuccessInLastMonth)}
              </Title>
              <span>{t("Success")}</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default EChart;
