import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// LOGIN API CALL
export const organizationList = (page, current, search) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.organization.list}${page == "" ? "" : "?page=" + page}${
          current == "" ? "" : "&limit=" + current
        }${search == "" ? "" : "&search=" + search}`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.organization.list,
          list: response.data.data,
          filterList: response.data.data.map(function (item) {
            return {
              label: item.name,
              value: item._id,
            };
          }),
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const organizationCreate = (payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.admin.organization.create,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const organizationEdit = (payloads, id, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.organization.edit + id,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const organizationDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.admin.organization.details + id
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.organization.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const organizationAssignedList = (page, current, search, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.organization.assignedList + "?courseId=" + id}${
          page == "" ? "" : "&page=" + page
        }${current == "" ? "" : "&limit=" + current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.organization.assignedList,
          list: response.data.data,
          assignedListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const organizationUnAssignedList = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.admin.organization.unAssignedList + "?courseId=" + id
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.organization.unAssignedList,
          list: response.data.data.map(function (item) {
            return {
              label: item.name,
              value: item._id,
            };
          }),
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const assignCourseToOrganization = (id, payload, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.organization.assign + id,
        payload,
        {},
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const unAssignCourseToOrganization = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.course.unAssign,
        payload
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
