import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// DASHBOARD DETAILS API CALL
export const dashboardDetails = (page, current, search) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.dashboard.details);
      if (response.data.status) {
        dispatch({
          type: API.admin.dashboard.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
