import React, { useEffect, useState } from "react";
import { Col, Pagination, Progress, Row, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sectionProgressList } from "../../../../../../../redux/user/learner/section/action";

// DEFINE MAIN FUNCTION
const TrainerLearnedCourseSections = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const courseID = window.location.pathname.split("/").pop();

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfSection = useSelector((state) => state.learnerSection.list);
  let countsOfSection = useSelector((state) => state.learnerSection.listCounts);

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search) => {
    await dispatch(sectionProgressList(page, current, search, courseID));
    setLoading(false);
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setLoading(true);
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };

  // SECTION LIST TABLE COLUMNS DATA
  const sectionTableColumns = [
    {
      title: t("Title"),
      dataIndex: "title",
      width: "48%",
    },
    {
      title: t("Progress"),
      width: "40%",
      render: (icon, value) => {
        return (
          <>
            <div className="percent-progress-animation percent-progress">
              <Progress className={styles.antProgress} percent={value.completedPercentage} size="small" />
            </div>
          </>
        );
      },
    },
    {
      title: t("Action"),
      width: "12%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/company/videos/learned/${courseID}/${value._id}`);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.filterData}>
                <div className={styles.filterIcone}>
                  {" "}
                  <FeatherIcon icon="grid" fill="black" size={20} />
                </div>
                <div className={styles.filterMenu}>
                  <FeatherIcon icon="menu" fill="black" size={20} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.latestVideoContent} style={{ marginTop: "30px" }}>
        <div className="table-responsive">
          <Table
            columns={sectionTableColumns}
            dataSource={listOfSection}
            loading={loading}
            pagination={false}
            className="ant-border-space"
          />
        </div>
        <div className="tabelsPagenation" style={{ padding: "0" }}>
          <Pagination
            total={countsOfSection.totalCount}
            showSizeChanger
            pageSizeOptions={[10, 20, 50, 100]}
            defaultPageSize={pageSize}
            defaultCurrent={currentPage}
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerLearnedCourseSections;
