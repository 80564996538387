import React, { useEffect } from "react";
import { Card, Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDetails } from "../../redux/admin/dashboard/action";
import FeatherIcon from "feather-icons-react";
import EChart from "../../components/admin/chart/EChart";
import LineChart from "../../components/admin/chart/LineChart";
import { organizationList } from "../../redux/admin/organization/action";
import moment from "moment";

// DEFINE MAIN FUNCTION
const Home = () => {
  // DEFINE ALL STATES
  const { Title } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // DEFINE USEEFFECT FOR DASHBOARD DETAILS
  useEffect(() => {
    details();
  }, []);

  // DEFINE FUNCTION FOR DASHBOARD DETAILS
  const details = async () => {
    await dispatch(dashboardDetails());
    await dispatch(organizationList(1, 5, ""));
  };

  // DEFINE ALL LISTS WITH REDUX
  let detailsOfDashbaord = useSelector((state) => state.dashboard.details);
  let listOfOrganization = useSelector((state) => state.organization.list);

  // DEFINE DASHBOARD TOTAL COUNTS DATA
  const count = [
    {
      today: t("Total Organization"),
      title: detailsOfDashbaord?.totalOrganizations,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="40"
          height="40"
          viewBox="0 0 256 256"
          enableBackground="new 0 0 256 256"
        >
          <g>
            <g>
              <path fill="#fff" d="M123.1,59.1h28.4V65h-28.4V59.1z"></path>
              <path fill="#fff" d="M141.6,168.3h14.2v17.6h-14.2V168.3z"></path>
              <path fill="#fff" d="M87.8,95.9h28.4v5.8H87.8V95.9z"></path>
              <path
                fill="#fff"
                d="M80.1,107.6v78.3h37.2v-78.3H81.9H80.1z M86,119h25.2v5.9H86V119z M86,135.2h25.2v5.9H86V135.2z M86,151.9h25.2v5.9H86V151.9z M86,168.6h25.2v5.9H86V168.6z"
              ></path>
              <path
                fill="#fff"
                d="M123.1,70.9v30.8v84.2h12.6v-23.5h26v23.5h14.2v-115h-18.6H123.1z M169.6,131.6h-40.2v-5.9h40.2V131.6z M169.6,112.2h-40.2v-5.9h40.2V112.2z M169.6,92.8h-40.2v-5.9h40.2V92.8z"
              ></path>
              <path
                fill="#fff"
                d="M128,10C62.8,10,10,62.8,10,128c0,65.2,52.8,118,118,118c65.2,0,118-52.8,118-118C246,62.8,193.2,10,128,10z M206.7,191.7h-24.8h-20.1h-26h-12.6h-5.9H74.2H49.3v-5.9h24.8v-84.2h7.7V90h35.3V70.9V65V53.2h40.2V65h24.5v120.9h24.8L206.7,191.7L206.7,191.7z"
              ></path>
            </g>
          </g>
        </svg>
      ),
      bnb: "bnb2",
    },
    {
      today: t("Total Administrators"),
      title: detailsOfDashbaord?.totalTrainers,
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={0}
        >
          <path
            d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
            fill="#fff"
          ></path>
          <path
            d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
            fill="#fff"
          ></path>
          <path
            d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
            fill="#fff"
          ></path>
          <path
            d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
            fill="#fff"
          ></path>
        </svg>
      ),
      bnb: "bnb2",
    },
    {
      today: t("Total Learners"),
      title: detailsOfDashbaord?.totalLearners,
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={0}
        >
          <path
            d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
            fill="#fff"
          ></path>
          <path
            d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
            fill="#fff"
          ></path>
          <path
            d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
            fill="#fff"
          ></path>
          <path
            d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
            fill="#fff"
          ></path>
        </svg>
      ),
      bnb: "bnb2",
    },
    {
      today: t("Total Videos"),
      title: detailsOfDashbaord?.totalVideos,
      icon: (
        <svg
          version="1.0"
          width="26"
          height="26"
          viewBox="0 0 1280.000000 1280.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
            fill="white"
            stroke="none"
          >
            <path d="M6120 12794 c-710 -48 -1205 -142 -1790 -342 -674 -231 -1331 -585 -1885 -1017 -302 -236 -722 -639 -952 -915 -851 -1021 -1339 -2202 -1469 -3555 -22 -224 -25 -826 -6 -1045 50 -570 150 -1054 320 -1560 398 -1179 1112 -2203 2067 -2965 920 -733 2023 -1197 3200 -1344 311 -39 425 -45 800 -45 372 0 552 11 840 50 1210 163 2339 663 3295 1459 195 162 608 574 767 765 851 1022 1339 2202 1469 3555 22 224 25 826 6 1045 -39 446 -108 834 -217 1225 -250 898 -677 1711 -1284 2440 -158 190 -572 604 -761 762 -1019 849 -2214 1343 -3550 1469 -154 14 -724 26 -850 18z m717 -1279 c787 -72 1483 -295 2158 -688 857 -500 1570 -1266 2005 -2152 275 -559 429 -1085 506 -1728 25 -202 30 -755 10 -973 -62 -673 -223 -1253 -511 -1839 -497 -1010 -1329 -1843 -2330 -2335 -559 -275 -1085 -429 -1728 -506 -202 -25 -755 -30 -973 -10 -799 73 -1491 294 -2169 689 -1158 676 -2032 1823 -2366 3102 -117 452 -163 812 -162 1290 0 532 67 993 214 1482 316 1049 947 1969 1803 2632 359 278 757 508 1166 674 457 186 967 312 1440 356 63 6 131 13 150 14 111 11 642 5 787 -8z" />
            <path d="M4980 8648 c-60 -31 -99 -92 -141 -220 l-34 -103 2 -640 c1 -352 7 -1234 13 -1960 11 -1226 13 -1326 30 -1400 67 -289 179 -342 506 -237 12 4 774 441 1694 972 1567 904 1677 969 1740 1032 92 91 134 174 128 255 -6 81 -41 146 -123 228 -67 67 -141 111 -1760 1051 -1186 689 -1709 988 -1755 1002 -151 48 -234 53 -300 20z" />
          </g>
        </svg>
      ),
      bnb: "bnb2",
    },
    {
      today: t("Total Views"),
      title: detailsOfDashbaord?.totalViews,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 32 32"
          height="32px"
          id="svg2"
          version="1.1"
          viewBox="0 0 32 32"
          width="32px"
        >
          <g id="background">
            <rect fill="none" height="32" width="32" />
          </g>
          <g id="view" fill="#fff">
            <circle cx="16" cy="16" r="6" />
            <path d="M16,6C6,6,0,15.938,0,15.938S6,26,16,26s16-10,16-10S26,6,16,6z M16,24c-8.75,0-13.5-8-13.5-8S7.25,8,16,8s13.5,8,13.5,8   S24.75,24,16,24z" />
          </g>
        </svg>
      ),
      bnb: "bnb2",
    },
    {
      today: t("Total Success Videos"),
      title: detailsOfDashbaord?.totalSuccessVideos,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 512 512"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 512 512"
          height="30px"
        >
          <path
            d="M256,6.998c-137.533,0-249,111.467-249,249c0,137.534,111.467,249,249,249s249-111.467,249-249  C505,118.464,393.533,6.998,256,6.998z M256,485.078c-126.309,0-229.08-102.771-229.08-229.081  c0-126.31,102.771-229.08,229.08-229.08c126.31,0,229.08,102.771,229.08,229.08C485.08,382.307,382.31,485.078,256,485.078z"
            fill="#fff"
          />
          <polygon
            fill="#fff"
            points="384.235,158.192 216.919,325.518 127.862,236.481 113.72,250.624 216.919,353.803 398.28,172.334   "
          />
        </svg>
      ),
      bnb: "bnb2",
    },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="layout-content">
        {/* DEFINE ALL COUNTS COMPONENT */}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          {/* DEFINE E CHART COMPONENT */}
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <EChart data={detailsOfDashbaord} />
            </Card>
          </Col>
          {/* DEFINE LINE CHART COMPONENT */}
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart data={detailsOfDashbaord} />
            </Card>
          </Col>
        </Row>

        {/* DEFINE LATEST ORGANIZATION TABLE DATA COMPONENT */}
        <Row gutter={[24, 0]}>
          <Col span={24} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>{t("Organisations")}</Title>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <button
                      onClick={() => {
                        navigate("/organization");
                      }}
                      className="add-btn"
                    >
                      {t("View All")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th>{t("Course")}</th>
                      <th>{t("Learner")}</th>
                      <th>{t("Administrator")}</th>
                      <th>{t("Phone Number")}</th>
                      <th>{t("Created")}</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfOrganization?.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <h6>
                            <img
                              src={d?.image}
                              alt=""
                              className="avatar-sm mr-10"
                            />{" "}
                            {d?.name}
                          </h6>
                        </td>

                        <td>
                          <span className="text-xs font-weight-bold">
                            {d?.courseCount}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d?.learnerCount}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d?.trainerCount}{" "}
                          </span>
                        </td>
                        <td>{d?.phoneNumber}</td>
                        <td>{moment(d?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>
                          <div className="ant-employed">
                            <FeatherIcon
                              icon={"eye"}
                              onClick={() => {
                                navigate(`/organization/${d?._id}`);
                              }}
                              style={{ cursor: "pointer" }}
                              color="rgb(11, 35, 34)"
                              size={22}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Home;
