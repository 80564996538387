import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";
import { filterUniqueByKey } from "../../../config/CommonFuntion";

// LOGIN API CALL
export const allVideosList = (page, current) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.video.allList}
        ${page == "" ? "" : "?page=" + page}${
          current == "" ? "" : "&limit=" + current
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.video.allList,
          allVideoList: response.data.data.map(function (item) {
            return {
              label: item.videoURL,
              value: item.videoURL,
              videoLength: item.videoLength,
            };
          }),
          allVideoListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// LOGIN API CALL
export const videoList = (page, current, search, courseId, sectionId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.video.list}${page == "" ? "" : "?page=" + page}${
          current == "" ? "" : "&limit=" + current
        }${page == "" ? "?" : "&"}courseId=${courseId}&sectionId=${sectionId}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.video.list,
          list: response.data.data.map(function (item) {
            return {
              ...item,
              updatedQuestions: item?.questions?.map(function (item, index) {
                let correct = +item.correctAnswer - 1;
                return {
                  key: `${index + 1}`,
                  time: item.time,
                  label: item.question,
                  correctAnswer: item?.options[correct]["optionKey"],
                  children: item.options,
                };
              }),
            };
          }),
          allCourseVideoList: filterUniqueByKey(
            response.data.data.map(function (item) {
              return {
                label: item.title,
                value: item._id,
              };
            }),
            "label"
          ),
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const trainerVideoList = (
  page,
  current,
  search,
  userID,
  courseId,
  sectionId
) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.video.list + userID}${
          page == "" ? "" : "?page=" + page
        }${current == "" ? "" : "&limit=" + current}${
          page == "" ? "?" : "&"
        }courseId=${courseId}&sectionId=${sectionId}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.video.list,
          list: response.data.data.map(function (item) {
            return {
              ...item,
              updatedQuestions: item?.questions?.map(function (item, index) {
                let correct = +item.correctAnswer - 1;
                return {
                  key: `${index + 1}`,
                  time: item.time,
                  label: item.question,
                  correctAnswer: item?.options[correct]["optionKey"],
                  children: item.options,
                };
              }),
            };
          }),
          allCourseVideoList: filterUniqueByKey(
            response.data.data.map(function (item) {
              return {
                label: item.title,
                value: item._id,
              };
            }),
            "label"
          ),
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const sectionQueProgressDetails = (userId, videoId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.trainer.video.details + userId + "/" + videoId
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.video.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const videoCreate = (payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.admin.video.create,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const videoDelete = (payloads) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.admin.video.delete + payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const videoEdit = (id, payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.video.edit + id,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const videosDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.video.details + id);
      if (response.data.status) {
        dispatch({
          type: API.admin.video.edit,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
