import { API } from "../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  list: undefined,
  listCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  details: undefined,
  assignedListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  unAssignedListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  assignedList: undefined,
  unAssignedList: [],
};

//DEFINE AUTH REDUCER FUNCTION
const CourseReducer = (state = initState, action) => {
  switch (action.type) {
    case API.admin.course.list:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
      };
    case API.admin.course.assignedList:
      return {
        ...state,
        assignedList: action.assignedList,
        assignedListCounts: action.assignedListCounts,
      };
    case API.admin.course.unAssignedList:
      return {
        ...state,
        unAssignedList: action.unAssignedList,
        unAssignedListCounts: action.unAssignedListCounts,
      };
    case API.admin.course.edit:
      return {
        ...state,
        details: action.details,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default CourseReducer;
