import Cookies from "js-cookie";
import { API } from "../../config/api/Index";
import { getItem } from "../../utility/localStorageControl";

//ALL TYPE WISE DATA RETURN
const initState = {
  login: Cookies.get("logedIn") ? true : false,
  userDetails: getItem("userDetails") ? getItem("userDetails") : {},
};

//DEFINE AUTH REDUCER FUNCTION
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case API.admin.auth.login:
      return {
        ...state,
        login: action.login,
        userDetails: action.userDetails,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default AuthReducer;
