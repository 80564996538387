import React, { useState } from "react";
import { Row, Col, Card, Descriptions, Avatar, Form, Modal, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";

// Images
import BgProfile from "../../assets/images/admin/bg-profile.jpg";
import { useTranslation } from "react-i18next";
import { updateProfile } from "../../redux/authentication/action";

// DEFINE MAIN FUNCTION
const Profile = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  let authDetails = useSelector((state) => state?.auth?.userDetails);

  const onFinish = async (values) => {
    setLoading(true);
    let result = await dispatch(updateProfile(values));
    if (result) {
      setProfileModal(false);
      setLoading(false);
      form.resetFields();
    } else {
      setLoading(false);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR CREATE & EDIT COURSE  */}
      <Modal
        title={t("Edit Profile")}
        centered
        open={profileModal}
        okText={t("Save")}
        onOk={() => {
          if (!loading) {
            form.submit();
          }
        }}
        confirmLoading={loading}
        onCancel={() => {
          if (!loading) {
            setProfileModal(false);
            form.resetFields();
          }
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={t("Name")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("Please enter name!"),
              },
            ]}
          >
            <Input placeholder={t("enter name")} style={{ height: "45px" }} />
          </Form.Item>
          <Form.Item
            label={t("Location")}
            name="location"
            rules={[
              {
                required: true,
                message: t("Please enter location!"),
              },
            ]}
          >
            <Input.TextArea rows={2} placeholder={t("enter location")} />
          </Form.Item>
        </Form>
      </Modal>

      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={
                    "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  }
                />
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {authDetails?.firstName}
                  </h4>
                  <p> {authDetails?.role}</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            title={
              <h6 className="font-semibold m-0">{t("Profile Information")}</h6>
            }
            extra={
              <FeatherIcon
                icon={"edit"}
                size={20}
                onClick={() => {
                  form.setFieldsValue({
                    firstName: authDetails?.firstName,
                    location: authDetails?.location
                      ? authDetails?.location
                      : "French",
                  });
                  setProfileModal(true);
                }}
              />
            }
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <hr />
            <Descriptions>
              <Descriptions.Item label={t("Full Name")} span={3}>
                {authDetails?.firstName}
              </Descriptions.Item>
              <Descriptions.Item label={t("Email")} span={3}>
                {authDetails?.email}
              </Descriptions.Item>
              <Descriptions.Item label={t("Location")} span={3}>
                {authDetails?.location ? authDetails?.location : "French"}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Profile;
