import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TrainerDashboard from "../../container/user/trainer/index/Index";
import TraningVideos from "../../container/user/trainer/company/videos/Index";
import TrainerProfile from "../../container/user/trainer/profile";
import Learners from "../../container/user/trainer/company/learners/Index";
import TrainerStactistics from "../../container/user/trainer/company/stactistics/Index";
import TrainerCompanyWorkSpace from "../../container/user/trainer/company/worksSpace/course/Index";
import TrainerCompanyTrainWorkSpace from "../../container/user/trainer/company/trainer-worksSpace/course/Index";
import TrainerLearnedCourseSections from "../../container/user/trainer/company/worksSpace/learned/section/Index";
import TrainerNotLearnedCourseSections from "../../container/user/trainer/company/worksSpace/notLearned/section/Index";
import TrainerLearnedCourseVideos from "../../container/user/trainer/company/worksSpace/learned/videos/Index";
import TrainerNotLearnedCourseVideos from "../../container/user/trainer/company/worksSpace/notLearned/videos/Index";

import TrainerLearnedSections from "../../container/user/trainer/company/trainer-worksSpace/learned/section/Index";
import TrainerNotLearnedSections from "../../container/user/trainer/company/trainer-worksSpace/notLearned/section/Index";
import TrainerLearnedVideos from "../../container/user/trainer/company/trainer-worksSpace/learned/videos/Index";
import TrainerNotLearnedVideos from "../../container/user/trainer/company/trainer-worksSpace/notLearned/videos/Index";
import TrainerNotLearnedSectionCard from "../../container/user/trainer/company/trainer-worksSpace/notLearned/sectionCard/Index";
// DEFINE MAIN FUNCTION
const TrainerPages = ({ width }) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <Routes>
      <Route exact path={`/`} element={<TrainerDashboard width={width} />} />
      <Route exact path={`/profile`} element={<TrainerProfile />} />
      {/* <Route exact path={`/company/videos`} element={<TraningVideos />} /> */}
      <Route
        exact
        path={`/company/stactistics`}
        element={<TrainerStactistics />}
      />
      <Route exact path={`/company/learner`} element={<Learners />} />
      <Route
        exact
        path={`/company/learner/:id`}
        element={<TrainerCompanyTrainWorkSpace width={width} />}
      />
      <Route
        exact
        path={`/company/learner/:id/:id`}
        element={<TrainerNotLearnedSections />}
      />
      <Route
        exact
        path={`/company/videos`}
        element={<TrainerCompanyWorkSpace />}
      />
      <Route
        exact
        path={`/company/videos/learned/:id`}
        element={<TrainerLearnedCourseSections />}
      />
      <Route
        exact
        path={`/company/videos/notLearned/:id`}
        element={<TrainerNotLearnedCourseSections />}
      />
      <Route
        exact
        path={`/company/videos/notLearned/:id/:id`}
        element={<TrainerNotLearnedCourseVideos />}
      />
      <Route
        exact
        path={`/company/videos/learned/:id/:id`}
        element={<TrainerLearnedCourseVideos width={width} />}
      />
      {/* <Route
        exact
        path={`/company/traningProcess`}
        element={<CompanyTraningProcess />}
      /> */}
      <Route
        exact
        path={`/company/video/learned/:id`}
        element={<TrainerLearnedSections />}
      />
      <Route
        exact
        path={`/company/video/notLearned/:id`}
        element={<TrainerNotLearnedSections />}
      />
      <Route
        exact
        path={`/company/video/notLearned/:id/:id`}
        element={<TrainerNotLearnedVideos />}
      />
      <Route
        exact
        path={`/company/video/notLearned/:id/:id/:id`}
        element={<TrainerNotLearnedSectionCard />}
      />
      <Route
        exact
        path={`/company/video/notLearned/:id/:id/:id/:id`}
        element={<TrainerNotLearnedVideos />}
      />
      <Route
        exact
        path={`/company/video/learned/:id/:id`}
        element={<TrainerLearnedVideos width={width} />}
      />
      <Route exact path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerPages;
