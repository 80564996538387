import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// LOGIN API CALL
export const sectionList = (page, current, search, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.admin.section.list
        }?page=${page}&limit=${current}&courseId=${id}${
          search == "" ? "" : "&search=" + search
        }`
      );
      // window.location.reload();
      if (response.data.status) {
        dispatch({
          type: API.admin.section.list,
          list: response.data.data,
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return { status: true, data: response.data.totalPages };
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const sectionCreate = (payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.admin.section.create,
        payloads,
        {},
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const sectionDelete = (payloads) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.admin.section.delete + payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const sectionEdit = (payloads, id, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.section.edit + id,
        payloads,
        {},
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const sectionsDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.section.details + id);
      if (response.data.status) {
        dispatch({
          type: API.admin.section.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
