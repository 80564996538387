// DEFINE THE LOCAL STORAGE GET ITEM FUNCTION
const getItem = (key) => {
  const data =
    typeof localStorage !== "undefined" ? localStorage.getItem(key) : "";
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

// DEFINE THE LOCAL STORAGE SET ITEM FUNCTION
const setItem = (key, value) => {
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
  return localStorage.setItem(key, stringify);
};

// DEFINE THE LOCAL STORAGE REMOVE ITEM FUNCTION
const removeItem = (key) => {
  return localStorage.removeItem(key);
};

// DEFINE THE LOCAL STORAGE EXPORT ALL FUNCTIONS
export { getItem, setItem, removeItem };
