import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Pagination, Row, Skeleton } from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { allCourseList } from "../../../../../../redux/user/learner/course/action";
import { getItem } from "../../../../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const TrainerCompanyWorkSpace = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      coursesList(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfCourses = useSelector((state) => state.learnerCourse.allCourseList);

  let countsOfCourses = useSelector(
    (state) => state.learnerCourse.allCourseListCounts
  );

  // DEFINE FUNCTION FOR COURSE LIST
  const coursesList = async (page, current, search) => {
    await dispatch(
      allCourseList(
        page,
        current,
        search,
        getItem("userDetails")?.organization?._id
      )
    );
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    coursesList(page, current, searchData);
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.headerComponent}>
        <span>{t("Courses List")}</span>
        <hr />
      </div>

      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          {/* <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
            <div className={styles.filterData}>
              <div className={styles.filterIcone}>
                {" "}
                <FeatherIcon icon="grid" fill="black" size={20} />
              </div>
              <div className={styles.filterMenu}>
                <FeatherIcon icon="menu" fill="black" size={20} />
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className={styles.latestVideoContent}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfCourses ? (
            listOfCourses.length > 0 ? (
              listOfCourses.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Card
                        onClick={() => {
                          navigate(`/company/videos/notLearned/${value._id}`);
                        }}
                        hoverable
                        cover={
                          <img
                            alt=""
                            src={
                              value.image
                                ? value.image == ""
                                  ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                  : value.image
                                : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                            }
                          />
                        }
                      >
                        <Meta
                          title={value.title}
                          description={value.discription}
                        />
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                {" "}
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(6).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>

        {listOfCourses?.length > 0 ? (
          <div className="tabelsPagenation">
            <Pagination
              total={countsOfCourses.totalCount}
              showSizeChanger
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={onPageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerCompanyWorkSpace;
