import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Switch,
} from "antd";
import { getItem, setItem } from "../../../../utility/localStorageControl";
import FeatherIcon from "feather-icons-react";
import { GoldOutlined, UserOutlined } from "@ant-design/icons";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import {
  allUserList,
  usersEdit,
  resetPassword,
} from "../../../../redux/user/learner/users/action";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { convertTrainerToAdministrator } from "../../../../utility/Commofunction";

const items = [
  {
    id: 1,
    name: "Be notified when a new  experience is created",
    isChecked: true,
  },
  {
    id: 2,
    name: "Be notified when an experience returns to draft mode",
    isChecked: true,
  },
  { id: 3, name: "Be notified when invited to a workspace", isChecked: true },
  {
    id: 4,
    name: "Be notified when a new experience is deleted",
    isChecked: true,
  },
  {
    id: 5,
    name: "Be notified when a new experience is created",
    isChecked: true,
  },
  {
    id: 6,
    name: "Be notified when an experience is published",
    isChecked: true,
  },
];

// DEFINE MAIN FUNCTION
const LearnerProfile = ({ width }) => {
  const [checkedItems, setCheckedItems] = useState(
    items.map((item) => item.isChecked)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [changePasswordForm] = Form.useForm();
  const [editProfileForm] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const handleSwitchChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      userList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);
  const userList = async () => {
    await dispatch(allUserList(getItem("userDetails")?._id));
    setLoading(false);
  };
  let listOfUsers = useSelector((state) => state.userDetails.usersList);

  const data11 = [
    {
      workspace: "vegos sales",
      company: "vegos Industries",
      roles: { type: "Learner", refresh: true },
    },
    {
      workspace: "vegos sales",
      company: "vegos Industries",
      roles: { type: "Learner", refresh: false },
    },
    {
      workspace: "vegos sales",
      company: "vegos Industries",
      roles: { type: "Learner", refresh: false },
    },
  ];

  const onChangePasswordFormSubmit = (value) => {
    setChangePasswordModal(false);
    changePasswordForm.resetFields();
  };

  const onChangeProfileEditSubmit = (value) => {
    setEditProfileModal(false);
    editProfileForm.resetFields();
  };
  const editUser = async () => {
    const formData = editProfileForm.getFieldsValue();
    if (
      formData.email &&
      formData.firstName &&
      formData.lastName &&
      formData.name
    ) {
      await dispatch(usersEdit(formData, getItem("userDetails")?._id));
      userList();
    }
  };
  const changePassword = async () => {
    const formData = changePasswordForm.getFieldsValue();
    if (formData.password && formData.confirmPassword) {
      let payload = {
        userId: getItem("userDetails")?._id,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      };
      await dispatch(resetPassword(payload));
      userList();
    }
  };
  // DEFINE USEEFFECT
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Modal
        title={t("Change Password")}
        centered
        open={changePasswordModal}
        okText={t("Confirm")}
        cancelText={t("Cancel")}
        onOk={() => {
          changePasswordForm.submit();
          changePassword();
        }}
        onCancel={() => {
          setChangePasswordModal(false);
          changePasswordForm.resetFields();
        }}
        className="profilePageModals"
      >
        <Form
          form={changePasswordForm}
          layout="vertical"
          onFinish={onChangePasswordFormSubmit}
        >
          {/* <Form.Item
            label={t("Current Password")}
            name="currentPassword"
            rules={[
              {
                required: true,
                message: t("Please input your current password!"),
              },
            ]}
          >
            <Input.Password
              placeholder={t("enter your current password")}
              style={{ height: "45px" }}
            />
          </Form.Item> */}
          <Form.Item
            label={t("New Password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("Please input your new password!"),
              },
            ]}
          >
            <Input.Password
              placeholder={t("Enter your new password")}
              style={{ height: "45px" }}
            />
          </Form.Item>{" "}
          <Form.Item
            label={t("Confirm Password")}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: t("Please input your confirm password!"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t("The two passwords that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={t("Enter your confirm password")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Edit User")}
        centered
        open={editProfileModal}
        okText={t("Confirm")}
        cancelText={t("Cancel")}
        onOk={() => {
          editProfileForm.submit();
          editUser();
        }}
        onCancel={() => {
          setEditProfileModal(false);
          editProfileForm.resetFields();
        }}
        className="profilePageModals"
      >
        <Form
          form={editProfileForm}
          layout="vertical"
          onFinish={onChangeProfileEditSubmit}
        >
          <Row gutter={25}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Email")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("Please enter your email!"),
                  },
                  {
                    type: "email",
                    message: t("Please enter a valid email!"),
                  },
                ]}
              >
                <Input
                  disabled
                  placeholder={t("Enter your email")}
                  style={{
                    height: "45px",
                    backgroundColor: "white",
                    color: "rgb(0 0 0 / 60%)",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("First Name")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter your first name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter your first name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>{" "}
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Last Name")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter your last name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter your last name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Please enter your name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter your name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>{" "}
          </Row>
        </Form>
      </Modal>

      <div>
        <h1 data-aos="fade-left">{t("Profile page")}</h1>
        <div className={style.mainDiv}>
          <Row justify={"space-between"} align={"middle"}>
            <Col data-aos="fade-right">
              <Row
                gutter={25}
                justify={width < 425 ? "start" : ""}
                align={"middle"}
              >
                <Col>
                  <div className={style.container}>
                    <div className={style.image}>
                      {" "}
                      {listOfUsers?.name
                        ? `${listOfUsers?.name.split(" ")[0].charAt(0)}${
                            listOfUsers?.name.split(" ")[1]
                              ? listOfUsers?.name.split(" ")[1].charAt(0)
                              : ""
                          }`
                        : ""}
                    </div>
                    <span>
                      {" "}
                      <FeatherIcon
                        icon="star"
                        color="white"
                        fill="white"
                        size={18}
                      />
                    </span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <h2 className={style.userName}>{listOfUsers?.name}</h2>
                    <div className={style.details}>
                      <p className={style.icon}>
                        <FeatherIcon icon="mail" size={18} />
                      </p>
                      <p className={style.email}>{listOfUsers?.email}</p>
                    </div>
                    <div className={style.details}>
                      <p className={style.icon}>
                        <UserOutlined style={{ fontSize: "20px" }} />
                      </p>
                      <span className={style.email}>
                        {listOfUsers?.position}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              data-aos="fade-left"
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={24}
            >
              <div className={style.tableContainer}>
                <p className={style.theader}>{t("Role")}:</p>
                <span className={style.tdata}>
                  {convertTrainerToAdministrator(listOfUsers?.role)}
                </span>
              </div>
              <div className={style.tableContainer}>
                <p className={style.theader}>{t("Status")}:</p>
                <p className={style.tdata}>{listOfUsers?.status}</p>
              </div>
              <div className={style.tableContainer}>
                <p className={style.theader}>{t("Created At")}:</p>
                <p className={style.tdata}>
                  {moment(listOfUsers?.createdAt).format("ll")}
                </p>
              </div>
            </Col>
            <Col data-aos="fade-left">
              <div className={style.iconStyle}>
                <Dropdown
                  menu={{
                    items: [
                      {
                        onClick: function () {
                          setChangePasswordModal(true);
                        },
                        key: "1",
                        icon: <FeatherIcon icon="lock" size={18} />,
                        label: t("Change PassWord"),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  arrow={{
                    pointAtCenter: true,
                  }}
                >
                  <span className={style.editIcon}>
                    <FeatherIcon
                      icon="more-vertical"
                      size={width < 425 ? 15 : 20}
                    />
                  </span>
                </Dropdown>
                <span
                  className={style.editIcon}
                  onClick={() => {
                    editProfileForm.setFieldsValue({
                      email: listOfUsers?.email,
                      firstName: listOfUsers?.firstName,
                      lastName: listOfUsers?.lastName,
                      name: listOfUsers?.name,
                    });
                    setEditProfileModal(true);
                  }}
                >
                  <FeatherIcon icon="edit-2" size={width < 425 ? 15 : 20} />
                </span>
              </div>
            </Col>
          </Row>
          {/* <Divider className={style.divider} />
          <div className={style.tableContainer}>
            <Row gutter={12}>
              <Col
                data-aos="fade-left"
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={24}
              >
                <p className={style.theader}>{t("Role")}</p>
                <p className={style.tdata}>{listOfUsers?.role}</p>
              </Col>
              <Col
                data-aos="fade-left"
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={24}
              >
                <p className={style.theader}>{t("Status")}</p>
                <p className={style.tdata}>{listOfUsers?.status}</p>
              </Col>
              <Col
                data-aos="fade-right"
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={24}
              >
                <p className={style.theader}>{t("Created At")}</p>
                <p className={style.tdata}>
                  {moment(listOfUsers?.createdAt).format("ll")}
                </p>
              </Col>*/}
          {/* <Col
                data-aos="fade-right"
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={24}
              >
                <p className={style.theader}>{t("Email")}</p>
                <p className={style.tdata}>{listOfUsers?.email}</p>
              </Col> */}
          {/* </Row> */}
          {/* </div>  */}
        </div>
      </div>

      {/* <div className={style.myAcess}>
        <h2 data-aos="fade-right">{t("My access")}</h2>
        <div className={style.mainDiv}>
          <div className={style.workspace}>
            <span className={style.workText} data-aos="fade-right">
              <GoldOutlined style={{ fontSize: "30px", margin: "5px" }} />
              {t("Workspace")}
            </span>
            <div className={style.worksTable}>
              <div className={style.tableHeadr}>
                <h3 data-aos="fade-right"> {t("Workspace")}</h3>
                <span>
                  <h3 data-aos="fade-left"> {t("Roles")}</h3>
                </span>
              </div>
              {data11.map((item, index) => (
                <div key={index}>
                  <div className={style.workspaceTable}>
                    <div
                      className={style.workspaceTable1}
                      data-aos="fade-right"
                    >
                      <div className={style.worksIcon}>
                        <GoldOutlined style={{ fontSize: "30px" }} />
                      </div>
                      <div className={style.tableDetails}>
                        <p className={style.productName}>{item.workspace}</p>
                        <span className={style.companyName}>
                          <GoldOutlined style={{ fontSize: "16px" }} />
                          <span style={{ padding: "0px 5px" }}>
                            {item.company}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className={style.rolesDiv} data-aos="fade-left">
                      <div className={style.roles}>
                        {item.roles.refresh ? (
                          <FeatherIcon
                            icon="refresh-ccw"
                            color="#FE9408"
                            size={20}
                          />
                        ) : (
                          ""
                        )}{" "}
                        {item.roles.type}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className={style.pagination} data-aos="fade-left">
                <Pagination total={50} showSizeChanger />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.userSetting}>
        <h2 data-aos="fade-right">{t("User settings")}</h2>
        <small data-aos="fade-left">
          {t("in-app notification preferences")}
        </small>
        <div>
          {items.map((item, index) => (
            <p key={item.id} data-aos="fade-left">
              <Switch
                className={style.toggelBtn}
                defaultChecked={checkedItems[index]}
                onChange={() => handleSwitchChange(index)}
              />
              <span style={{ padding: "10px 10px" }}>{item.name}</span>
            </p>
          ))}
        </div>
      </div> */}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default LearnerProfile;
