import React from "react";
import AdminRoutes from "../../routes/privetRoutes/AdminRoutes";
import Home from "../../container/admin/Home";
import Profile from "../../container/admin/Profile";
import { Navigate } from "react-router-dom";
import "./main.css";
import "./responsive.css";
import Organization from "../../container/admin/Organization";
import OrganizationDetails from "../../container/admin/OrganizationDetails";
import Member from "../../container/admin/Member";
import MemberDetails from "../../container/admin/MemberDetails";
import Course from "../../container/admin/Course";
import CourseDetails from "../../container/admin/CourseDetails";
import AddVideo from "../../container/admin/AddVideo";

// DEFINE MAIN FUNCTION
const AdminLayout = () => {
  const adminRoutes = [
    {
      path: "/dashboard",
      element: <Home />,
      exact: true,
    },
    {
      path: "/organization",
      element: <Organization />,
      exact: true,
    },
    {
      path: "/organization/:id",
      element: <OrganizationDetails />,
      exact: true,
    },
    {
      path: "/members",
      element: <Member />,
      exact: true,
    },
    {
      path: "/members/:id",
      element: <MemberDetails />,
      exact: true,
    },
    {
      path: "/course",
      element: <Course />,
      exact: true,
    },
    {
      path: "/course/:id",
      element: <CourseDetails />,
      exact: true,
    },
    {
      path: "/course/:id/:id",
      element: <AddVideo />,
      exact: true,
    },
    {
      path: "/profile",
      element: <Profile />,
      exact: true,
    },
    { path: "/*", element: <Navigate to="/dashboard" /> },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <AdminRoutes adminRoutes={adminRoutes} />
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AdminLayout;
