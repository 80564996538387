import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// DEFINE MAIN FUNCTION
const LineChart = ({ data }) => {
  // DEFINE ALL STATES
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();

  // DEFINE LINE CHART DATA
  const lineChartData = {
    series: [
      {
        name: t("Trainers"),
        data: data?.activeUserGraph?.trainersTotalCount,
        offsetY: 0,
      },
      {
        name: t("Learners"),
        data: data?.activeUserGraph?.learnerTotalCount,
        offsetY: 0,
      },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
        },
        categories: data?.activeUserGraph?.month,
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>{t("Active Users")}</Title>
        </div>
        <div className="sales">
          <ul>
            <li>
              {<MinusOutlined />} {t("Trainers")}
            </li>
            <li>
              {<MinusOutlined />} {t("Learners")}
            </li>
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChartData.options}
        series={lineChartData.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default LineChart;
