import { message } from "antd";
import { API } from "../../../../config/api/Index";
import { DataService } from "../../../../config/DataService";

export const statisticsList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(`${API.learner.statistics.list}`);
      if (response.data.status) {
        dispatch({
          type: API.learner.statistics.list,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const statisticsDetailsList = (courseId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.statistics.details}` + courseId
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.statistics.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const statisticsTrainerList = (userId, orgId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.statistics.list}${
          userId == "" ? "" : "?userId=" + userId
        }${orgId == "" ? "" : "&organizationId=" + orgId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.statistics.list,
          trainerDetails: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const statisticsQuestionList = (userId, courseId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.statistics.details}` + userId + "/" + courseId
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.statistics.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
