import React, { useEffect, useState } from "react";
import { Row, Col, Card, Input, Skeleton, Empty, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { memberDetails } from "../../redux/admin/members/action";
import { capitalizeFirstLetter } from "../../config/CommonFuntion";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { courseAssignedList } from "../../redux/admin/course/action";

// DEFINE MAIN FUNCTION
const MemberDetails = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [coursePageSize, setCoursePageSize] = useState(10);
  const [courseSearchData, setCourseSearchData] = useState("");
  const [courseCurrentPage, setCourseCurrentPage] = useState(1);
  const memberID = window.location.pathname.split("/").pop();

  // DEFINE USEEFFECT WITH COURSE SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (userDetails?.organization?._id) {
        coursesList(
          courseCurrentPage,
          coursePageSize,
          courseSearchData,
          userDetails?.organization?._id
        );
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [courseSearchData]);

  // DEFINE USEEFFECT FOR USER DETAILS
  useEffect(() => {
    details();
  }, []);

  // DEFINE FUNCTION FOR USER DETAILS
  const details = async () => {
    let response = await dispatch(memberDetails(memberID));
    if (response.status) {
      setUserDetails(response.data);
      coursesList(1, 8, "", response?.data?.organization?._id);
    } else {
      setUserDetails({});
    }
  };

  // DEFINE ALL LISTS WITH REDUX
  let detailsOfMember = useSelector((state) => state.member.details);
  let listOfAssignedCourseCounts = useSelector(
    (state) => state.course.listCounts
  );
  let listOfAssignedCourse = useSelector((state) => state.course.assignedList);

  // DEFINE FUNCTION FOR COURSE TABLE PAGE CHANGE
  const onCoursePageChange = async (page, current) => {
    setCourseCurrentPage(page);
    setCoursePageSize(current);
    coursesList(
      page,
      current,
      courseSearchData,
      userDetails?.organization?._id
    );
  };

  // DEFINE FUNCTION FOR COURSE LIST DATA
  const coursesList = async (page, current, search, organizationID) => {
    await dispatch(courseAssignedList(page, current, search, organizationID));
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="tabled">
        <button
          className="add-btn"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            navigate(`/members`);
          }}
        >
          <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
        </button>

        <Row gutter={25}>
          <Col span={24}>
            {/* DEFINE USER DETAILS COMPONENT */}
            <Row gutter={25}>
              <Col xxl={8} xl={8} lg={8} md={10} sm={24} xs={24}>
                <Card bordered={false} className="mb-24 memberProfileImg">
                  {detailsOfMember ? (
                    <img
                      src={
                        detailsOfMember.profilePic == ""
                          ? "https://bootdey.com/img/Content/avatar/avatar7.png"
                          : detailsOfMember.profilePic
                      }
                    />
                  ) : (
                    <Skeleton.Image active={true} size={"large"} />
                  )}
                  <span className="name">
                    {detailsOfMember && detailsOfMember.name}
                  </span>
                  <span className="role">
                    {detailsOfMember &&
                      capitalizeFirstLetter(detailsOfMember.role)}
                  </span>
                </Card>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={14} sm={24} xs={24}>
                <Card bordered={false} className="mb-24 memberProfileDetails">
                  <Row gutter={25}>
                    <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                      <span>{t("Created Date")} :</span>
                    </Col>
                    <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                      <label>
                        {detailsOfMember &&
                          moment(detailsOfMember.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={25}>
                    <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                      <span>{t("Organization")} :</span>
                    </Col>
                    <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                      <label>
                        {detailsOfMember && detailsOfMember?.organization?.name
                          ? detailsOfMember?.organization?.name
                          : "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={25}>
                    <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                      <span>{t("Email")} :</span>
                    </Col>
                    <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                      <label>{detailsOfMember && detailsOfMember.email}</label>
                    </Col>
                  </Row>
                  <Row gutter={25}>
                    <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                      <span>{t("Course Completed")} :</span>
                    </Col>
                    <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                      <label>
                        {detailsOfMember && detailsOfMember?.courseCount
                          ? detailsOfMember?.courseCount
                          : 0}
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={25}>
                    <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                      <span>{t("Position")} :</span>
                    </Col>
                    <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                      <label>
                        {capitalizeFirstLetter(detailsOfMember?.position)}
                      </label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            {/* DEFINE COURSE LIST COMPONENT */}
            <Card
              extra={
                <Input
                  addonBefore={<SearchOutlined />}
                  onChange={(e) => setCourseSearchData(e.target.value)}
                  placeholder={t("search courses")}
                />
              }
              bordered={false}
              className="criclebox tablespace mb-24 coursesCard"
              title={t("Courses")}
            >
              <Row gutter={25}>
                {listOfAssignedCourse == null ? (
                  Array.from(Array(8).keys())
                    .map((name) => ({ name }))
                    .map((item, index) => {
                      return (
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          className="course-list-skeleton"
                          xs={24}
                        >
                          <Card
                            cover={
                              <Skeleton.Input
                                key={index}
                                active={true}
                                size={"large"}
                              />
                            }
                          >
                            <Meta title={""} description={""} />
                          </Card>
                        </Col>
                      );
                    })
                ) : listOfAssignedCourse && listOfAssignedCourse.length > 0 ? (
                  listOfAssignedCourse.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          <Card
                            onClick={() => {
                              navigate(`/course/${value._id}`, {
                                replace: true,
                              });
                            }}
                            hoverable
                            cover={
                              <img
                                alt=""
                                src={
                                  value.image
                                    ? value.image == ""
                                      ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                      : value.image
                                    : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                }
                              />
                            }
                          >
                            <Meta
                              style={{ padding: "20px" }}
                              title={value.title}
                              description={value.discription}
                            />
                          </Card>
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <div className="no-data-component">
                    <Empty />
                  </div>
                )}
              </Row>
              <div className="tabelsPagenation">
                <Pagination
                  total={listOfAssignedCourse?.length}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={coursePageSize}
                  defaultCurrent={1}
                  current={courseCurrentPage}
                  onChange={onCoursePageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default MemberDetails;
