import React, { useEffect } from "react";
import Aos from "aos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { otpVerification } from "../../../redux/authentication/action";
import styles from "./style.module.css";
import { getItem } from "../../../utility/localStorageControl";

const OtpVerify = () => {
  // DEFINE ALL STATES
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // DEFINE USEEFFECT
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  // LOGIN FORM SUBMIT FUNCTION DEFINE
  const onFinish = async (values) => {
    const userId = window.location.pathname.split("/").pop();
    let payload = {
      userId: userId,
      otp: values.otp,
    };
    let resp = await dispatch(otpVerification(payload));
    if (resp) {
      navigate(`/reset-password/${userId}`);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.LoginComponent}>
          <div className={styles.loginContent}>
            <div className={styles.loginDetails}>
              <h5 data-aos="fade-right"> {t("OTP Verification")} </h5>
              <p data-aos="fade-left">
                {t("Please enter the OTP sent to your registered Email ID")}
              </p>
            </div>
            <div className={styles.loginFormSection}>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  data-aos="fade-right"
                  label={t("OTP")}
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: t("Please enter otp!"),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder={t("Enter OTP")}
                    style={{ height: "45px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <button
                    className={styles.loginButton}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("Submit")}
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={styles.backgroundImageContent}>
            <div className={styles.imageContainer}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAACtBAMAAAC0KMWCAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAVUExURUdwTPHx9vDx9vDx9vHy9/Pz+e/w9XrTQnAAAAAGdFJOUwB7ptFPJqEDGaYAAAKASURBVHja7d3NacNAFIVRb1KAUoJUgkB7g0qIW1D/JcSEkEVwyJ/BulfnlKB8DO/NGHI6QYHLyzoM4zT7EuR6uqzr8HzNePvgoxB4GJ+HcZyX7Qafh5DD+H2muJmxoEmZKc7XmWKet5/yzciaKQRN6oK3CZq0w/jXM4WgSV3wBE3Vgido9rjgDX9d8ARN1YInaHaQ8fyAjAXNbu8pBE3VPYWg6R+NBU3qPYWgOeZhLGi+P4zffhUUl7Gg+TxTTCEzhaD5YqbY022boPnPTLFsrfyNLXiCJuEwLp0pBH2s07hpwRP04S8qtsPTQvxFxbTIWND5FxXaFXT+RYXDWNAFjx8yFrQFT9CYKQSNBU/QRz+MzRSCzj+MLXiCNlMg6P284MlY0AWHsZlC0OELnsNY0F7wEPROXvBkLOj8FzwzhaAteAjaCx6CvseCJ2NBmykQtAUPQd/jV0EyFnTBgmemELQFD0E//AXPgkdy0BY8CoJ220ZB0BY88oO24FEQtJmC/KAteBQE7QWP/KC94FEQtJmCgqC94JEftMOYgqAL/kc0grbgkR+0FzwKgvaCR37QFjwKgvaCR0HQFjw6gjZTUBW0T4CgQdAgaBA0ggZBg6BB0CBoBA2CBkGDoEHQCBoEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDSCBkGDoEHQIGgEDYIGQYOgQdAIGgQNggZBg6ARNAgaBA2CBkEjaBA0CBoEDYJG0CBoEDQIGgSNoEHQIGgQNAgaQYOgQdAgaAQNggZBg6BB0AgaBA2CBkGDoBE0CBoEDYIGQSNoEDQIGgQNgkbQIGgQNAgaBE2rV8fAx8SI153KAAAAAElFTkSuQmCC" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
