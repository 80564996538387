import { API } from "../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  details: {
    totalOrganizations: 0,
    totalTrainers: 0,
    totalLearners: 0,
    totalVideos: 0,
    totalViews: 0,
    totalSuccessVideos: 0,
    activeUserGraph: {
      trainersTotalCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      learnerTotalCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      month: ["", "", "", "", "", "", "", "", ""],
    },
    organisationGraph: {
      organisationTotalCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      month: ["", "", "", "", "", "", "", "", ""],
    },
    totalOrganizationsInLastMonth: 0,
    totalLearnersInLastMonth: 0,
    totalVideosInLastMonth: 0,
    totalViewsInLastMonth: 0,
    totalSuccessInLastMonth: 0,
  },
};

//DEFINE AUTH REDUCER FUNCTION
const DashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case API.admin.dashboard.details:
      return {
        ...state,
        details: action.details,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default DashboardReducer;
