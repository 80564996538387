import { createStore, applyMiddleware, compose } from "redux";
import { withExtraArgument } from "redux-thunk";
import rootReducers from "./RootReducers";

const store = createStore(
  rootReducers,
  compose(applyMiddleware(withExtraArgument()))
);

export default store;
