import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  Pagination,
  Popconfirm,
  Skeleton,
} from "antd";
import {
  organizationAssignedList,
  organizationUnAssignedList,
} from "../../redux/admin/organization/action";
import {
  assignOrganizationToCourse,
  coursesDetails,
  unAssignOrganizationToCourse,
} from "../../redux/admin/course/action";
import {
  sectionCreate,
  sectionDelete,
  sectionEdit,
  sectionList,
} from "../../redux/admin/section/action";

// DEFINE MAIN FUNCTION
const CourseDetails = () => {
  // DEFINE ALL STATES
  const { Title } = Typography;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sectionForm] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sectionModal, setSectionModal] = useState(false);
  const [sectionPageSize, setSectionPageSize] = useState(10);
  const [sectionModalType, setSectionModalType] = useState("");
  const [sectionSearchData, setSectionSearchData] = useState("");
  const [sectionCurrentPage, setSectionCurrentPage] = useState(1);
  const [organizationModal, setOrganizationModal] = useState(false);
  const [sectionTableLoading, setSectionTableLoading] = useState(true);
  const [sectionModalLoading, setSectionModalLoading] = useState(false);
  const [organizationSearchData, setOrganizationSearchData] = useState("");
  const [organizationTableLoading, setOrganizationTableLoading] =
    useState(true);
  const [organizationModalLoading, setOrganizationModalLoading] =
    useState(false);
  const courseID = window.location.pathname.split("/").pop();

  // DEFINE USEEFFECT FOR SECTION & ORGANIZATION DETAILS
  useEffect(() => {
    details();
  }, []);

  // DEFINE USEEFFECT WITH ORGANIZATION SEARCH DATA
  useEffect(() => {
    setOrganizationTableLoading(true);
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, organizationSearchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearchData]);

  // DEFINE USEEFFECT WITH SECTION SEARCH DATA
  useEffect(() => {
    setSectionTableLoading(true);
    const delayDebounceFn = setTimeout(() => {
      sectionsList(sectionCurrentPage, sectionPageSize, sectionSearchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [sectionSearchData]);

  // DEFINE ALL LISTS WITH REDUX
  let detailsOfCourse = useSelector((state) => state.course.details);
  let listOfSection = useSelector((state) => state.section.list);
  let countsOfSection = useSelector((state) => state.section.listCounts);
  let listOfAssignedOrganization = useSelector(
    (state) => state.organization.assignedList
  );
  let countsOfAssignedOrganization = useSelector(
    (state) => state.organization.assignedListCounts
  );
  let listOfUnAssignOrganization = useSelector(
    (state) => state.organization.unAssignedList
  );

  // DEFINE FUNCTION FOR SECTION & ORGANIZATION DETAILS
  const details = async () => {
    await dispatch(coursesDetails(courseID));
    await dispatch(organizationUnAssignedList(courseID));
  };

  // DEFINE FUNCTION FOR ORGANIZATION TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setOrganizationTableLoading(true);
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, organizationSearchData);
  };

  // DEFINE FUNCTION FOR SECTION TABLE PAGE CHANGE
  const onSectionPageChange = async (page, current) => {
    setSectionTableLoading(true);
    setSectionCurrentPage(page);
    setSectionPageSize(current);
    sectionsList(page, current, organizationSearchData);
  };

  // DEFINE FUNCTION FOR SECTION LIST
  const sectionsList = async (page, current, search) => {
    await dispatch(sectionList(page, current, search, courseID));
    setSectionTableLoading(false);
  };

  // DEFINE FUNCTION FOR ORGANIZATION LIST
  const list = async (page, current, search) => {
    await dispatch(organizationAssignedList(page, current, search, courseID));
    setOrganizationTableLoading(false);
  };

  // DEFINE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setSectionModalLoading(false);
    }
  };

  // DEFINE API LOADING PERCENTAGE
  const loadingAssignPercentage = (val) => {
    if (val == 100) {
      setOrganizationModalLoading(false);
    }
  };

  // ORGANIZATION ASSIGN SUBMIT FUNCTION DEFINE
  const onCourseAssignSubmit = async (values) => {
    setOrganizationModalLoading(true);
    let result = await dispatch(
      assignOrganizationToCourse(courseID, values, loadingAssignPercentage)
    );
    if (result) {
      setOrganizationModal(false);
      form.resetFields();
      setOrganizationTableLoading(true);
      list(currentPage, pageSize, organizationSearchData);
      await dispatch(organizationUnAssignedList(courseID));
    } else {
      setOrganizationModalLoading(false);
    }
  };

  // ORGANIZATION UNASSIGN SUBMIT FUNCTION DEFINE
  const onUnAssignCourse = async (values) => {
    setOrganizationTableLoading(true);
    let result = await dispatch(
      unAssignOrganizationToCourse(courseID, {
        courseId: courseID,
        organizationId: values._id,
      })
    );
    if (result) {
      list(currentPage, pageSize, organizationSearchData);
      await dispatch(organizationUnAssignedList(courseID));
    } else {
      setOrganizationTableLoading(false);
    }
  };

  // SECTION DELETE SUBMIT FUNCTION DEFINE
  const deleteSections = async (values) => {
    setSectionTableLoading(true);
    let result = await dispatch(sectionDelete(values._id));
    if (result) {
      sectionsList(sectionCurrentPage, sectionPageSize, sectionSearchData);
    }
  };

  // SECTION FORM SUBMIT FUNCTION DEFINE
  const onSectionCreateSubmit = async (values) => {
    setSectionModalLoading(true);
    if (sectionModalType == "Create") {
      let result = await dispatch(
        sectionCreate(
          { title: values.title, course: courseID },
          loadingPercentage
        )
      );
      if (result) {
        setSectionModal(false);
        setSectionModalType("");
        sectionForm.resetFields();
        setSectionTableLoading(true);
        sectionsList(sectionCurrentPage, sectionPageSize, sectionSearchData);
      } else {
        setSectionModalLoading(false);
      }
    } else {
      let result = await dispatch(
        sectionEdit(
          { title: values.title, course: courseID },
          sectionModalType._id,
          loadingPercentage
        )
      );
      if (result) {
        setSectionModal(false);
        setSectionModalType("");
        sectionForm.resetFields();
        setSectionTableLoading(true);
        sectionsList(sectionCurrentPage, sectionPageSize, sectionSearchData);
      } else {
        setSectionModalLoading(false);
      }
    }
  };

  // ASSIGNED ORGANIZATION TABLE COLUMNS DATA
  const AssignedOrganizationtTableColumns = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "36%",
      render: (icon, value) => {
        return (
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              src={
                value.image == ""
                  ? "https://www.svgrepo.com/show/490660/company.svg"
                  : value.image
              }
              size={25}
              alt=""
            />
            <div className="avatar-info">
              <Title level={5}>{value.name}</Title>
            </div>
          </Avatar.Group>
        );
      },
    },
    {
      title: t("Learner"),
      dataIndex: "learnerCount",
      width: "15%",
    },
    {
      title: t("Administrator"),
      dataIndex: "trainerCount",
      width: "15%",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phoneNumber",
      width: "15%",
    },
    {
      title: t("Created"),
      key: "createdAt",
      dataIndex: "createdAt",
      width: "10%",
      render: (icon, value) => {
        return (
          <div>
            <span>{moment(value.createdAt).format("DD-MM-YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: t("Action"),
      width: "10%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <Popconfirm
                trigger={"hover"}
                title={t("UnAssign Organization")}
                description={t("Are you sure to UnAssign this Organization?")}
                onConfirm={() => {
                  onUnAssignCourse(value);
                }}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <button className="add-btn">
                  <FeatherIcon icon="x" size={18} />
                </button>
              </Popconfirm>
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/organization/${value._id}`);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // SECTION LIST TABLE COLUMNS DATA
  const sectionTableColumns = [
    {
      title: t("Title"),
      dataIndex: "title",
      width: "88%",
    },
    {
      title: t("Action"),
      width: "12%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <FeatherIcon
                icon={"edit"}
                onClick={() => {
                  sectionForm.setFieldValue("title", value.title);
                  setSectionModal(true);
                  setSectionModalType(value);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />

              <Popconfirm
                trigger={"hover"}
                title={t("Delete Section")}
                description={t("Are you sure to delete this section?")}
                onConfirm={() => {
                  deleteSections(value);
                }}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <FeatherIcon
                  icon="trash"
                  style={{ cursor: "pointer" }}
                  color="rgb(11, 35, 34)"
                  size={22}
                />
              </Popconfirm>
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/course/${courseID}/${value._id}`);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE ASSIGN ORGANIZATION MODAL*/}
      <Modal
        title={t("Assign Organization")}
        centered
        open={organizationModal}
        okText={t("Assign")}
        onOk={() => {
          if (!organizationModalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!organizationModalLoading) {
            form.resetFields();
            setOrganizationModal(false);
          }
        }}
        confirmLoading={organizationModalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onCourseAssignSubmit}>
          <Form.Item
            label={t("Organizations")}
            name="assignedTo"
            rules={[
              {
                required: true,
                message: t("Please select Organization!"),
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("select organization")}
              style={{
                minHeight: "45px",
              }}
              options={listOfUnAssignOrganization}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* CREATE & EDIT SECTION MODAL */}
      <Modal
        title={`${sectionModalType == "Create" ? t("Create") : t("Edit")} ${t(
          "Section"
        )}`}
        centered
        open={sectionModal}
        okText={sectionModalType == "Create" ? t("Create") : t("Edit")}
        onOk={() => {
          if (!sectionModalLoading) {
            sectionForm.submit();
          }
        }}
        onCancel={() => {
          if (!sectionModalLoading) {
            sectionForm.resetFields();
            setSectionModal(false);
            setSectionModalType("");
          }
        }}
        confirmLoading={sectionModalLoading}
      >
        <Form
          layout="vertical"
          form={sectionForm}
          onFinish={onSectionCreateSubmit}
        >
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[
              {
                required: true,
                message: t("Please enter section title!"),
              },
            ]}
          >
            <Input
              placeholder={t("section title")}
              style={{
                Height: "45px",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* DEFINE COURSE DETAILS COMPONENT */}
      <div className="tabled">
        <button
          className="add-btn"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            navigate(`/course`);
          }}
        >
          <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
        </button>

        <Row gutter={25}>
          <Col xxl={8} xl={8} lg={8} md={10} sm={24} xs={24}>
            <Card bordered={false} className="mb-24 organizationProfileImg">
              {detailsOfCourse ? (
                <img
                  src={
                    detailsOfCourse?.image == ""
                      ? "https://img.freepik.com/free-psd/silver-letters-glass-building-facade_145275-162.jpg"
                      : detailsOfCourse?.image
                  }
                  style={{ borderRadius: "16px" }}
                />
              ) : (
                <Skeleton.Image active={true} size={"large"} />
              )}
              <span className="name">
                {detailsOfCourse && detailsOfCourse?.title}
              </span>
            </Card>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={14} sm={24} xs={24}>
            <Card
              bordered={false}
              className="mb-24 organizationProfileDetails courseDetails"
            >
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Created Date")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse &&
                      moment(detailsOfCourse?.createdAt).format(
                        "DD-MM-YYYY, HH:MM"
                      )}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Last Update Date")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse &&
                      moment(detailsOfCourse?.updatedAt).format(
                        "DD-MM-YYYY, HH:MM"
                      )}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Total Sections")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse && detailsOfCourse?.totalSections
                      ? detailsOfCourse?.totalSections
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Total Videos")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse && detailsOfCourse?.totalVideos
                      ? detailsOfCourse?.totalVideos
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Assigned Organizations")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse && detailsOfCourse?.assignedOrganization
                      ? detailsOfCourse?.assignedOrganization
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} xl={10} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Description")} :</span>
                </Col>
                <Col xxl={16} xl={14} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfCourse && detailsOfCourse?.discription}
                  </label>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* DEFINE SECTIONS & ASSIGNED ORGANIZATION LIST COMPONENT */}
        <Row gutter={25}>
          <Col span={24}>
            {/* DEFINE SECTIONS LIST COMPONENT */}
            <Card
              extra={
                <>
                  <Input
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setSectionSearchData(e.target.value)}
                    placeholder={t("Search sections")}
                  />
                  <button
                    className="add-btn"
                    onClick={() => {
                      setSectionModal(true);
                      setSectionModalType("Create");
                    }}
                  >
                    {t("Add")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
              bordered={false}
              className="criclebox tablespace mb-24 coursesCard"
              title="Sections"
            >
              <div className="table-responsive">
                <Table
                  columns={sectionTableColumns}
                  dataSource={listOfSection}
                  loading={sectionTableLoading}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <div className="tabelsPagenation">
                <Pagination
                  total={countsOfSection.totalCount}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={sectionPageSize}
                  defaultCurrent={sectionCurrentPage}
                  onChange={onSectionPageChange}
                />
              </div>
            </Card>

            {/* DEFINE ASSIGNED ORGANIZATION LIST COMPONENT */}
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 membersCard"
              title="Assigned Organizations"
              extra={
                <>
                  <Input
                    addonBefore={<SearchOutlined />}
                    value={organizationSearchData}
                    style={{ height: "40px", width: "250px" }}
                    onChange={(e) => setOrganizationSearchData(e.target.value)}
                    placeholder={t("Search Organizations")}
                  />
                  <button
                    className="add-btn"
                    onClick={() => {
                      setOrganizationModal(true);
                    }}
                  >
                    {t("Assign")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={AssignedOrganizationtTableColumns}
                  dataSource={listOfAssignedOrganization}
                  loading={organizationTableLoading}
                  pagination={false}
                  className="ant-border-space"
                />
                <div className="tabelsPagenation">
                  <Pagination
                    total={countsOfAssignedOrganization.totalCount}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    defaultPageSize={pageSize}
                    defaultCurrent={1}
                    current={currentPage}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default CourseDetails;
