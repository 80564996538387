import { message } from "antd";
import { API } from "../../../../config/api/Index";
import { DataService } from "../../../../config/DataService";

export const videoProgressList = (
  page,
  current,
  search,
  courseId,
  sectionId,
  pagenationStatus
) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.video.list}${page == "" ? "" : "?page=" + page}${
          current == "" ? "" : "&limit=" + current
        }${search == "" ? "" : "&search=" + search}${
          page == "" ? "?" : "&"
        }courseId=${courseId}&sectionId=${sectionId}&pagination=${pagenationStatus}`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.video.list,
          list: response.data.data,
          videoList: response.data.data.map(function (item) {
            return item["videoURL"];
          }),
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const videoWatchHistory = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.learner.video.watchHistory,
        payload
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const videoQuestionAnswer = async (payload) => {
  try {
    const response = await DataService.post(
      API.learner.video.videoQuestionAnswer,
      payload
    );
    if (response.data.status) {
      return true;
    } else {
      return true;
    }
  } catch (err) {
    return true;
  }
};
