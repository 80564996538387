import { message } from "antd";
import { API } from "../../../../config/api/Index";
import { DataService } from "../../../../config/DataService";

export const allUserList = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(`${API.learner.users.list + id}`);
      if (response.data.status) {
        dispatch({
          type: API.learner.users.list,
          usersList: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// export const memberCreate = (payloads, loadingPercentage) => {
//   return async (dispatch) => {
//     try {
//       const response = await DataService.post(
//         API.admin.member.create,
//         payloads,
//         {
//           "Content-Type": "multipart/form-data",
//         },
//         loadingPercentage
//       );
//       if (response.data.status) {
//         message.success(response.data.message);
//         return true;
//       } else {
//         message.error(response.data.message);
//         return false;
//       }
//     } catch (err) {
//       return false;
//     }
//   };
// };

export const usersEdit = (payloads, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.learner.users.edit + id,
        payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const resetPassword = (payloads) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.learner.auth.resetPass,
        payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
// export const memberDetails = (id) => {
//   return async (dispatch) => {
//     try {
//       const response = await DataService.get(API.admin.member.details + id);
//       if (response.data.status) {
//         dispatch({
//           type: API.admin.member.edit,
//           details: response.data.data,
//         });
//         return { data: response.data.data, status: true };
//       } else {
//         message.error(response.data.message);
//         return { data: {}, status: false };
//       }
//     } catch (err) {
//       return { data: {}, status: false };
//     }
//   };
// };
// export const userDetails = (id) => {
//   return async (dispatch) => {
//     try {
//       const response = await DataService.get(API.admin.users.details + id);
//       if (response.data.status) {
//         dispatch({
//           type: API.admin.member.edit,
//           details: response.data.data,
//         });
//         return true;
//       } else {
//         message.error(response.data.message);
//         return false;
//       }
//     } catch (err) {
//       return false;
//     }
//   };
// };
