import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Pagination,
  Form,
  Modal,
  Empty,
  Select,
  Skeleton,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  courseCreate,
  courseEdit,
  courseList,
} from "../../redux/admin/course/action";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import { onUploadImageValidation } from "../../config/CommonFuntion";

// DEFINE MAIN FUNCTION
const Course = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const uploadImageRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [courseModal, setCourseModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfCourse = useSelector((state) => state.course.list);
  let countsOfCourse = useSelector((state) => state.course.listCounts);

  // DEFINE CREATE AND UPDATE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setLoading(false);
    }
  };

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search) => {
    await dispatch(courseList(page, current, search));
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };

  // COURSE FORM SUBMIT FUNCTION DEFINE
  const onFinish = async (values) => {
    setLoading(true);
    if (selectedData) {
      let formData = new FormData();
      if (uploadedFile !== selectedData.image) {
        formData.append("image", uploadedFile);
      }
      if (values?.note) {
        formData.append("note", values.note);
      }
      formData.append("title", values.title);
      formData.append("languageCode", values.languageCode);
      formData.append("discription", values.discription);
      let result = await dispatch(
        courseEdit(formData, selectedData._id, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setCourseModal(false);
        setSelectedData(null);
        form.resetFields();
        list(currentPage, pageSize, "");
      } else {
        setLoading(false);
      }
    } else {
      let formData = new FormData();
      formData.append("image", uploadedFile);
      formData.append("title", values.title);
      if (values?.note) {
        formData.append("note", values.note);
      }
      formData.append("discription", values.discription);
      let result = await dispatch(courseCreate(formData, loadingPercentage));
      if (result) {
        setUploadedFile(null);
        setCourseModal(false);
        setSelectedData(null);
        form.resetFields();
        let check = (countsOfCourse.totalCount / pageSize)
          .toFixed(1)
          .split(".");
        if (check.pop("0")) {
          list(Number(check[0]) + 1, pageSize, "");
          setCurrentPage(Number(check[0]) + 1);
        }
      } else {
        setLoading(false);
      }
    }
  };

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadedFile(e.target.files[0]);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR CREATE & EDIT COURSE  */}
      <Modal
        title={`${selectedData == null ? t("Create") : t("Edit")} ${t(
          "Course"
        )}`}
        centered
        open={courseModal}
        okText={`${selectedData == null ? t("Create") : t("Save")}`}
        onOk={() => {
          if (!loading) {
            form.submit();
          }
        }}
        confirmLoading={loading}
        onCancel={() => {
          if (!loading) {
            setCourseModal(false);
            setSelectedData(null);
            form.resetFields();
            setUploadedFile(null);
          }
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[
              {
                required: true,
                message: t("Please enter course title!"),
              },
            ]}
          >
            <Input
              placeholder={t("Enter course title")}
              style={{ height: "45px" }}
            />
          </Form.Item>
          <Form.Item label={t("Note")} name="note">
            <Input.TextArea rows={2} placeholder={t("Enter course note")} />
          </Form.Item>
          <Form.Item
            label={t("Description")}
            name="discription"
            rules={[
              {
                required: true,
                message: t("Please enter course discription!"),
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t("Enter course discription")}
            />
          </Form.Item>
          <Form.Item
            label={t("Language")}
            name="languageCode"
            rules={[
              {
                required: true,
                message: t("Please select language!"),
              },
            ]}
            className="memberRole"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("Select language")}
              style={{ height: "45px" }}
              options={[
                {
                  value: "en",
                  label: t("English"),
                },
                {
                  value: "fr",
                  label: t("French"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="image" label={t("Image")}>
            <div className="orgUploadedIMG">
              <div
                style={{
                  width: uploadedFile ? "392px" : "100%",
                  fontSize: uploadedFile ? "16px" : "16px",
                }}
                onClick={() => {
                  uploadImageRef.current.click();
                }}
              >
                <UploadOutlined
                  style={{
                    fontSize: uploadedFile ? "16px" : "20px",
                  }}
                />{" "}
                {t("Click to Upload")}
              </div>
              {uploadedFile ? (
                <>
                  {" "}
                  <img
                    src={
                      typeof uploadedFile == "string"
                        ? uploadedFile
                        : URL.createObjectURL(uploadedFile)
                    }
                  />
                  <label
                    onClick={() => {
                      setUploadedFile(null);
                      form.resetFields(["image"]);
                    }}
                  >
                    x
                  </label>
                </>
              ) : (
                ""
              )}
            </div>
            <input
              type="file"
              id="file"
              ref={uploadImageRef}
              style={{ display: "none" }}
              onChange={onUploadImageChange.bind(this)}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* DEFINE COURSE LIST COMPONENT */}
      <div className="tabled">
        <Row gutter={25}>
          <Col span={24}>
            <Card
              extra={
                <>
                  <Input
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder={t("Search courses")}
                  />
                  <button
                    className="add-btn"
                    onClick={() => {
                      setCourseModal(true);
                    }}
                  >
                    {t("Add")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
              bordered={false}
              className="criclebox tablespace mb-24 coursesCard"
              title={t("Courses")}
            >
              <Row gutter={25}>
                {listOfCourse ? (
                  listOfCourse.length > 0 ? (
                    listOfCourse.map((value, index) => {
                      return (
                        <>
                          <Col
                            key={index}
                            xxl={6}
                            xl={8}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                          >
                            <Card
                              onClick={() => {
                                navigate(`/course/${value._id}`);
                              }}
                              hoverable
                              cover={
                                <img
                                  alt=""
                                  src={
                                    value.image
                                      ? value.image == ""
                                        ? "https://media.istockphoto.com/id/1447732423/photo/mature-adult-woman-working-at-home.jpg?s=1024x1024&w=is&k=20&c=6kYyTDBh9sb4FD1dTYszzXfqW3Fa1bEgvR0S9-m1jg4="
                                        : value.image
                                      : "https://media.istockphoto.com/id/1447732423/photo/mature-adult-woman-working-at-home.jpg?s=1024x1024&w=is&k=20&c=6kYyTDBh9sb4FD1dTYszzXfqW3Fa1bEgvR0S9-m1jg4="
                                  }
                                />
                              }
                            >
                              <Meta
                                style={{ padding: "20px" }}
                                title={value.title}
                                description={value.discription}
                              />
                            </Card>
                            <div
                              className="courseEdit"
                              onClick={() => {
                                setUploadedFile(
                                  value.image
                                    ? value.image == ""
                                      ? "https://media.istockphoto.com/id/1447732423/photo/mature-adult-woman-working-at-home.jpg?s=1024x1024&w=is&k=20&c=6kYyTDBh9sb4FD1dTYszzXfqW3Fa1bEgvR0S9-m1jg4="
                                      : value.image
                                    : "https://media.istockphoto.com/id/1447732423/photo/mature-adult-woman-working-at-home.jpg?s=1024x1024&w=is&k=20&c=6kYyTDBh9sb4FD1dTYszzXfqW3Fa1bEgvR0S9-m1jg4="
                                );
                                form.setFieldsValue(value);
                                setCourseModal(true);
                                setSelectedData(value);
                              }}
                            >
                              <FeatherIcon
                                icon="edit-2"
                                size={18}
                                fill="rgb(11, 35, 34)"
                              />
                            </div>
                          </Col>
                        </>
                      );
                    })
                  ) : (
                    <div className="no-data-component">
                      {" "}
                      <Empty />
                    </div>
                  )
                ) : (
                  Array.from(Array(8).keys())
                    .map((name) => ({ name }))
                    .map((item, index) => {
                      return (
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          className="course-list-skeleton"
                          xs={24}
                        >
                          <Card
                            cover={
                              <Skeleton.Input
                                key={index}
                                active={true}
                                size={"large"}
                              />
                            }
                          >
                            <Meta title={""} description={""} />
                          </Card>
                        </Col>
                      );
                    })
                )}
              </Row>
              <div className="tabelsPagenation">
                <Pagination
                  total={countsOfCourse?.totalCount}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={pageSize}
                  defaultCurrent={1}
                  current={currentPage}
                  onChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Course;
