import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../../components/admin/Main";

// DEFINE MAIN FUNCTION
const AdminLayout = ({ adminRoutes }) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Routes>
        {adminRoutes.map((route, index) => (
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            element={<Main>{route.element}</Main>}
          />
        ))}
      </Routes>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AdminLayout;
