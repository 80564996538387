import { API } from "../../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  list: undefined,
  listCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  details: {},
  detail: {},
};

//DEFINE AUTH REDUCER FUNCTION
const LearnerSectionReducer = (state = initState, action) => {
  switch (action.type) {
    case API.learner.section.list:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
      };
    case API.trainer.section.list:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
      };
    case API.learner.section.details:
      return {
        ...state,
        details: action.details,
      };
    case API.trainer.section.details:
      return {
        ...state,
        details: action.details,
      };
    case API.learner.section.detail:
      return {
        ...state,
        detail: action.detail,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default LearnerSectionReducer;
