import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/user/navbar/Navbar";
import LearnerPages from "../../routes/privetRoutes/LearnerRoutes";
import TrainerPages from "../../routes/privetRoutes/TrainerRoutes";
import "./style.css";

// DEFINE MAIN FUNCTION
const UserLayout = ({ role }) => {
  // DEFINE ALL STATES
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  // DEFINE USEEFFECT
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="learnerContainer" ref={ref}>
        <Navbar role={role} />
        <div className="learnerPagesContainer">
          {role == "trainer" ? (
            <TrainerPages width={width} />
          ) : (
            <LearnerPages width={width} />
          )}
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default UserLayout;
