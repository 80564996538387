/**
 * For STAGING Environment
 */

export const API_ENDPOINT = `https://nexus-api.devstree.com/`;

/**
 * For PRODUCTIONS Environment
 */

// export const API_ENDPOINT = `https://api.pixeogroup.com/`;
