import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import FeatherIcon from "feather-icons-react";
import AOS from "aos";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../../../utility/localStorageControl";
import { getFirstLetters } from "../../../../config/CommonFuntion";

// DEFINE MAIN FUNCTION
const Index = ({ width }) => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentLatestVideoIndex, setCurrentLatestVideoIndex] = useState(0);

  // DEFINE USEEFFECT
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const latestTrainingReviewsVideo = [
    {
      name: "Total Count of Registered Learners",
      duration: "55 minutes",
      index: "1",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg1.png",
        "../../../images/user/latestVideoImg2.png",
        "../../../images/user/latestVideoImg3.png",
        "../../../images/user/latestVideoImg4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "224 minutes",
      index: "2",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Total Count of Registered Learners",
      duration: "224 minutes",
      index: "3",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "55 minutes",
      index: "4",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Total Count of Registered Learners",
      duration: "224 minutes",
      index: "5",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "55 minutes",
      index: "6",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Total Count of Registered Learners",
      duration: "11 minutes",
      index: "7",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg1.png",
        "../../../images/user/latestVideoImg2.png",
        "../../../images/user/latestVideoImg3.png",
        "../../../images/user/latestVideoImg4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "55 minutes",
      index: "8",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Total Count of Registered Learners",
      duration: "224 minutes",
      index: "9",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "55 minutes",
      index: "10",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Total Count of Registered Learners",
      duration: "224 minutes",
      index: "11",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "All the statistics of your learners",
      duration: "55 minutes",
      index: "12",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
  ];

  const latestTrainingReviewsVideoLength = 12;

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.userDetailsContent} data-aos="fade-right">
        <div className={styles.container}>
          <div className={styles.image}>
            {getFirstLetters(getItem("userDetails")?.name)}
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              {t("Welcome")} {getItem("userDetails")?.name}
            </div>
            <div className={styles.description}>
              {t("You are in the")} {getItem("userDetails")?.organization?.name}{" "}
              {t("Industries environment")}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.latestVideoContent} data-aos="fade-left">
        <Row gutter={12}>
          <Col
            xxl={8}
            xl={width < 1296 ? 24 : 8}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <div
              className={styles.exploreCard}
              onClick={() => {
                navigate("/company/videos");
              }}
            ></div>
            <div className={styles.exploreBackImageCardTextDiv}>
              <span>{t("Explore my environment")}</span>
            </div>
            <div
              className={styles.exploreBackCardButtonDiv}
              onClick={() => {
                navigate("/company/videos");
              }}
            >
              {" "}
              <FeatherIcon icon="chevron-right" size={36} />
            </div>
          </Col>
          {/* <Col
            xxl={16}
            xl={width < 1296 ? 24 : 16}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Row gutter={12}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                {" "}
                <div className={styles.latestVideoSlogen}>
                  <span>{t("Learners")}</span>
                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <div className={styles.latestVideoSlogen}>
                  <span>{t("Statistics")}</span>
                  <div className={styles.latestVideoButtons}>
                    <div
                      className={styles.latestVideoPrevButton}
                      onClick={() => {
                        if (currentLatestVideoIndex !== 0) {
                          setCurrentLatestVideoIndex(
                            currentLatestVideoIndex - 1
                          );
                        }
                      }}
                    >
                      <FeatherIcon
                        icon="arrow-left"
                        color={
                          currentLatestVideoIndex !== 0 ? "black" : "#c8cace"
                        }
                        size={22}
                      />
                    </div>
                    <div
                      className={styles.latestVideoNextButton}
                      onClick={() => {
                        if (width > 765) {
                          if (
                            latestTrainingReviewsVideoLength !==
                            currentLatestVideoIndex +
                              latestTrainingReviewsVideoLength / 2 +
                              1
                          ) {
                            setCurrentLatestVideoIndex(
                              currentLatestVideoIndex + 1
                            );
                          }
                        } else {
                          if (
                            latestTrainingReviewsVideoLength !==
                            currentLatestVideoIndex + 1
                          ) {
                            setCurrentLatestVideoIndex(
                              currentLatestVideoIndex + 1
                            );
                          }
                        }
                      }}
                    >
                      <FeatherIcon
                        icon="arrow-right"
                        size={22}
                        color={
                          width > 765
                            ? latestTrainingReviewsVideoLength !==
                              currentLatestVideoIndex +
                                latestTrainingReviewsVideoLength / 2 +
                                1
                              ? "black"
                              : "#c8cace"
                            : latestTrainingReviewsVideoLength !==
                              currentLatestVideoIndex + 1
                            ? "black"
                            : "#c8cace"
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <div className={styles.carousel}>
                <div
                  className={styles.carouselItems}
                  style={{
                    transform: `translateX(-${currentLatestVideoIndex * 100}%)`,
                  }}
                >
                  {latestTrainingReviewsVideo.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          <div className={styles.latestVideoModuleCard}>
                            <div
                              className={styles.latestVideoModuleCardImagesDiv}
                            >
                              <div>
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }1.png`)}
                                />
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }2.png`)}
                                />
                              </div>
                              <div>
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }3.png`)}
                                />
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }4.png`)}
                                />
                              </div>
                            </div>
                            <div
                              className={styles.latestVideoModuleCardNameDiv}
                            >
                              {value.index} - {value.name}
                            </div>
                            <div
                              className={styles.latestVideoModuleCardDetailsDiv}
                            >
                              <div></div>
                              <div className={styles.latestVideotime}>
                                <span>
                                  <svg
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z"></path>
                                  </svg>
                                </span>
                                <span>{value.duration}</span>
                              </div>
                              <div className={styles.latestVideoRate}></div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </div>
            </Row>
          </Col> */}
        </Row>
      </div>

      {/* <div className={styles.dashboardDetailsContent} data-aos="fade-right">
        <Row gutter={12}>
          {" "}
          <span className={styles.tutorialLabel}>
            <label>{t("VR headset / new module order")}</label>
            <div>
              {" "}
              <FeatherIcon icon="mail" size={18} />
              {t("Contact us")}
            </div>
          </span>
          <Col
            xxl={8}
            xl={8}
            lg={width < 1100 ? 12 : 8}
            md={12}
            sm={24}
            xs={24}
          >
            <div className={styles.tutorialCard}>
              <div className={styles.tutorialCardImage}>
                <img
                  src={require("../../../../assets/images/user/tutorialImage.png")}
                />
              </div>
              <span className={styles.tutorialCardTitle}>
                {t("VR headset")}
              </span>
              <div className={styles.tutorialCardDetails}>
                <span>
                  {t("Manage your VR headset orders")}
                  <br />
                  {t("or maintenance")}
                </span>
              </div>
              <div className={styles.tutorialCardButton}>
                <label>{t("See more")}</label>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={width < 1100 ? 12 : 8}
            md={12}
            sm={24}
            xs={24}
          >
            <div className={styles.tutorialCard}>
              <div className={styles.tutorialCardImage}>
                <img
                  src={require("../../../../assets/images/user/tutorialImage-1.png")}
                />
              </div>
              <span className={styles.tutorialCardTitle}>
                {t("New modules orders")}
              </span>
              <div className={styles.tutorialCardDetails}>
                <span>
                  {t("Create new videos orders or edit")}
                  <br />
                  {t("existing videos")}
                </span>
              </div>
              <div className={styles.tutorialCardButton}>
                <label>{t("See more")}</label>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={width < 1100 ? 12 : 8}
            md={12}
            sm={24}
            xs={24}
          >
            <div className={styles.tutorialCard}>
              <div className={styles.tutorialCardImage}>
                <img
                  src={require("../../../../assets/images/user/tutorialImage-2.png")}
                />
              </div>
              <span className={styles.tutorialCardTitle}>{t("Billing")}</span>
              <div className={styles.tutorialCardDetails}>
                <span>{t("Your billing here")}</span>
              </div>
              <div className={styles.tutorialCardButton}>
                <label>{t("See more")}</label>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Index;
