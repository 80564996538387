import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// LOGIN API CALL
export const memberList = (page, current, search, id, role) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.member.list}?page=${page}&limit=${current}${
          id == "" ? "" : "&organizationId=" + id
        }${role == "" ? "" : "&role=" + role}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.member.list,
          list: response.data.data,
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const allUserList = (page, current, search, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.users.list}?page=${page}&limit=${current}${
          id == "" ? "" : "&organizationId=" + id + "&role=learner"
        }${search == "" ? "" : "&search=" + search}`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.users.list,
          usersList: response.data.data,
          usersListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const memberCreate = (payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.admin.member.create,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const memberEdit = (payloads, id, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.member.edit + id,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const memberDelete = (payloads) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.admin.member.delete + payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const memberDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.member.details + id);
      if (response.data.status) {
        dispatch({
          type: API.admin.member.edit,
          details: response.data.data,
        });
        return { data: response.data.data, status: true };
      } else {
        message.error(response.data.message);
        return { data: {}, status: false };
      }
    } catch (err) {
      return { data: {}, status: false };
    }
  };
};
export const userDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.users.details + id);
      if (response.data.status) {
        dispatch({
          type: API.admin.member.edit,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
