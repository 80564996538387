import { API } from "../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  list: [],
  listCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  details: undefined,
  usersList: [],
  usersListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

//DEFINE AUTH REDUCER FUNCTION
const MemberReducer = (state = initState, action) => {
  switch (action.type) {
    case API.admin.member.list:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
      };
    case API.admin.users.list:
      return {
        ...state,
        usersList: action.usersList,
        usersListCounts: action.usersListCounts,
      };
    case API.admin.member.edit:
      return {
        ...state,
        details: action.details,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default MemberReducer;
