import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Pagination,
  Select,
  Modal,
  Empty,
  Skeleton,
  Spin,
  Collapse,
  ColorPicker,
  TimePicker,
} from "antd";
import Meta from "antd/es/card/Meta";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import {
  allVideosList,
  videoCreate,
  videoEdit,
  videoList,
} from "../../redux/admin/videos/action";
import { sectionsDetails } from "../../redux/admin/section/action";
import {
  addObjectInArray,
  convertDurationtoSeconds,
  convertSecondsToHours,
  convertSecondsToHoursMinuitesSeconds,
  convertSecondsToMinutes,
  convertSecondsToSeconds,
  deleteFromArray,
  findAndAddOptionArray,
  findAndDeleteOptionArray,
  findAndUpdateArray,
  findAndUpdateOptionArray,
  onUploadImageValidation,
} from "../../config/CommonFuntion";
import dayjs from "dayjs";

// DEFINE MAIN FUNCTION
const AddVideo = () => {
  // DEFINE ALL STATES
  const { confirm } = Modal;
  const videoRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadImageRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [questionsData, setQuestionsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadVideo, setUploadVideo] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [memberModalType, setMemberModalType] = useState("");
  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [uploadThumbnailImage, setUploadThumbnailImage] = useState(null);
  const [showVideoPreviewModal, setShowVideoPreviewModal] = useState(false);
  const [showQuestionComponent, setShowQuestionComponent] = useState(false);
  const sectionID = window.location.pathname.split("/").pop();
  const courseID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 2
    ];

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT FOR SECTION DETAILS
  useEffect(() => {
    details();
  }, []);

  // DEFINE USEEFFECT FOR THUMBNAIL IMAGE ONCHANGE CHECK IMAGE FIELD
  useEffect(() => {
    form.validateFields(["image"]);
  }, [uploadThumbnailImage]);

  // DEFINE USEEFFECT FOR VIDEO ONCHANGE CHECK VIDEO FIELD
  useEffect(() => {
    form.validateFields(["videoURL"]);
  }, [showPreviewButton]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfVideo = useSelector((state) => state.video.list);
  let listOfCourseVideoList = useSelector(
    (state) => state.video.allCourseVideoList
  );
  let countsOfVideosList = useSelector((state) => state.video.listCounts);
  let listOfAllVideosList = useSelector((state) => state.video.allVideoList);
  let detailsOfSection = useSelector((state) => state.section.details);

  // DEFINE FUNCTION FOR SECTION DETAILS
  const details = async () => {
    await dispatch(sectionsDetails(sectionID));
    await dispatch(allVideosList("", ""));
  };

  // DEFINE FUNCTION FOR VIDEO TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };

  // DEFINE FUNCTION FOR VIDEO LIST
  const list = async (page, current, search) => {
    await dispatch(videoList(page, current, search, courseID, sectionID));
  };

  // DEFINE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setLoading(false);
    }
  };

  // VIDEO FORM SUBMIT FUNCTION DEFINE
  const onFormSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("videoURL", values.videoURL);
    if (memberModalType == "Create") {
      formData.append("thumbnailImage", uploadThumbnailImage);
    } else {
      if (uploadThumbnailImage !== selectedData.thumbnailImage) {
        formData.append("thumbnailImage", uploadThumbnailImage);
      }
    }
    formData.append("discription", values.discription);
    formData.append(
      "videoLength",
      listOfAllVideosList?.find((val) => val.value == values.videoURL)
        ?.videoLength
    );
    formData.append("course", courseID);
    formData.append("courseSection", sectionID);

    let formatedQuestionsData = questionsData.map(function (item) {
      return {
        time: item?.time,
        question: item?.label,
        correctAnswer: item?.correctAnswer,
        options: item?.children,
      };
    });

    formatedQuestionsData.forEach((questionsValue, index) => {
      formData.append(`questions[${index}][time]`, questionsValue?.time);
      formData.append(
        `questions[${index}][correctAnswer]`,
        questionsValue?.correctAnswer
      );
      formData.append(
        `questions[${index}][question]`,
        questionsValue?.question
      );
      questionsValue?.options?.forEach((option, optIndex) => {
        formData.append(
          `questions[${index}][options][${optIndex}][option]`,
          option.option
        );
        formData.append(
          `questions[${index}][options][${optIndex}][optionKey]`,
          option.optionKey
        );
        formData.append(
          `questions[${index}][options][${optIndex}][color]`,
          option.color
        );
        formData.append(
          `questions[${index}][options][${optIndex}][navigateType]`,
          option.navigateType ? option.navigateType : "none"
        );
        formData.append(
          `questions[${index}][options][${optIndex}][navigateValue]`,
          option.navigateType == "" || option.navigateType == "none"
            ? ""
            : option.navigateValue
              ? option.navigateValue
              : ""
        );
      });
    });

    setLoading(false);

    if (memberModalType == "Create") {
      let result = await dispatch(videoCreate(formData, loadingPercentage));
      if (result) {
        setUploadThumbnailImage(null);
        setUploadVideo(null);
        form.resetFields();
        setMemberModalType("");
        setShowQuestionComponent(false);
        setQuestionsData([]);
        list(currentPage, pageSize, "");
      } else {
        setLoading(false);
      }
    } else {
      let result = await dispatch(
        videoEdit(selectedData._id, formData, loadingPercentage)
      );
      if (result) {
        setUploadThumbnailImage(null);
        setUploadVideo(null);
        form.resetFields();
        setSelectedData("");
        setMemberModalType("");
        setQuestionsData([]);
        setShowQuestionComponent(false);
        list(currentPage, pageSize, "");
      } else {
        setLoading(false);
      }
    }
  };

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadThumbnailImage(e.target.files[0]);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE SELECTED VIDEO PREVIEW MODAL */}
      <Modal
        title={t("Selected Video")}
        centered
        open={showVideoPreviewModal}
        footer={null}
        onCancel={() => {
          setShowVideoPreviewModal(false);
          videoRef.current.pause();
        }}
        width={650}
      >
        <video
          style={{ height: "600px", width: "600px", objectFit: "fill" }}
          src={uploadVideo}
          ref={videoRef}
          controls
        />
      </Modal>

      {/* VIDEOS TITLE COMPONENT */}
      <div className="tabled">
        <button
          className="add-btn"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            navigate(`/course/${courseID}`);
            setShowQuestionComponent(false);
          }}
        >
          <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
        </button>

        <Card bordered={false} className="mb-24 videoDetails">
          {" "}
          <Row gutter={4}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <h2>{detailsOfSection.title}</h2>
            </Col>
          </Row>
        </Card>

        {/* VIDEOS CREATE & EDIT , LIST COMPONENT */}
        <Row gutter={25}>
          <Col span={24}>
            <Card
              extra={
                <>
                  {memberModalType == "Create" || memberModalType == "Edit" ? (
                    <button
                      className="add-btn"
                      onClick={() => {
                        if (!loading) {
                          setMemberModalType("");
                          form.resetFields();
                          setUploadThumbnailImage(null);
                          setShowQuestionComponent(false);
                        }
                      }}
                    >
                      <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
                    </button>
                  ) : (
                    <>
                      <Input
                        onChange={(e) => setSearchData(e.target.value)}
                        addonBefore={<SearchOutlined />}
                        placeholder={t("Search courses")}
                      />
                      <button
                        className="add-btn"
                        onClick={() => {
                          setLoading(false);
                          setSelectedData({});
                          setMemberModalType("Create");
                          setQuestionsData([]);
                          setShowPreviewButton(false);
                        }}
                      >
                        {t("Add")} <FeatherIcon icon="plus" size={18} />
                      </button>
                    </>
                  )}{" "}
                </>
              }
              className={`mb-24 ${memberModalType == "Create" || memberModalType == "Edit"
                  ? ""
                  : "coursesCard"
                } videoCreateCard`}
              bordered={false}
              title={`${memberModalType}` + " " + t("Videos")}
            >
              {/* VIDEOS CREATE & EDIT COMPONENT */}
              {memberModalType == "Create" || memberModalType == "Edit" ? (
                <>
                  {" "}
                  <Form layout="vertical" form={form} onFinish={onFormSubmit}>
                    <Row gutter={25}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                          label={t("Title")}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: t("Please enter video title!"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("Enter video title")}
                            style={{ height: "45px" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="image"
                          rules={[
                            {
                              required: uploadThumbnailImage ? false : true,
                              message: t("Please upload thumbnail image!"),
                            },
                          ]}
                          label={t("Thumbnail Image")}
                        >
                          <div className="orgUploadedIMG">
                            <div
                              style={{
                                width: "100%",
                                fontSize: uploadThumbnailImage
                                  ? "16px"
                                  : "16px",
                              }}
                              onClick={() => {
                                uploadImageRef.current.click();
                              }}
                            >
                              <UploadOutlined
                                style={{
                                  fontSize: uploadThumbnailImage
                                    ? "16px"
                                    : "20px",
                                }}
                              />{" "}
                              {t("Click to Upload")}
                            </div>
                            {uploadThumbnailImage ? (
                              <>
                                {" "}
                                <img
                                  src={
                                    typeof uploadThumbnailImage == "string"
                                      ? uploadThumbnailImage
                                      : URL.createObjectURL(
                                        uploadThumbnailImage
                                      )
                                  }
                                />
                                <label
                                  onClick={() => {
                                    setUploadThumbnailImage(null);
                                    form.resetFields(["thumbnailImage"]);
                                  }}
                                >
                                  x
                                </label>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <input
                            type="file"
                            id="file"
                            ref={uploadImageRef}
                            style={{ display: "none" }}
                            onChange={onUploadImageChange.bind(this)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                          label={t("Description")}
                          name="discription"
                          rules={[
                            {
                              required: true,
                              message: t(
                                "Please enter organization discription!"
                              ),
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={4}
                            placeholder={t("Enter organization discription")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="videoURL"
                          style={{ marginBottom: "14px" }}
                          rules={[
                            {
                              required: true,
                              message: t("Please select video!"),
                            },
                          ]}
                          label={t("Select Video")}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            placeholder={t("select video")}
                            style={{
                              minHeight: "45px",
                            }}
                            onChange={(val) => {
                              setShowPreviewButton(val);
                            }}
                            options={listOfAllVideosList}
                          />
                        </Form.Item>
                        {showPreviewButton ? (
                          <>
                            <div style={{ display: "flex", columnGap: "10px" }}>
                              <span
                                className="add-btn"
                                onClick={() => {
                                  setUploadVideo(showPreviewButton);
                                  setShowVideoPreviewModal(true);
                                }}
                              >
                                {t("Preview")}{" "}
                                <FeatherIcon icon="eye" size={18} />
                              </span>
                              <span
                                className="add-btn"
                                onClick={() => {
                                  if (questionsData?.length == 0) {
                                    setQuestionsData([
                                      ...questionsData,
                                      {
                                        key: (
                                          questionsData?.length + 1
                                        ).toString(),
                                        time: "01",
                                        label:
                                          t("This is question") +
                                          ` ${questionsData?.length + 1}`,
                                        correctAnswer: "1",
                                        children: [
                                          {
                                            option: "Option 1",
                                            color: "#0b2322",
                                            optionKey: "1",
                                            navigateType: "none",
                                            navigateValue:
                                              listOfCourseVideoList[0]?.value,
                                          },
                                        ],
                                      },
                                    ]);
                                  }
                                  setShowQuestionComponent(true);
                                }}
                              >
                                {t("Add Questions")}{" "}
                                <FeatherIcon icon="plus" size={18} />
                              </span>
                              <span
                                className="add-btn"
                                onClick={() => {
                                  setShowPreviewButton(null);
                                  form.resetFields(["videoURL"]);
                                  setUploadVideo(null);
                                  setShowQuestionComponent(false);
                                }}
                              >
                                {t("Cancel")} <FeatherIcon icon="x" size={18} />
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Form>
                  {!showQuestionComponent ? (
                    <button
                      className="add-btn"
                      style={{ marginTop: "14px", width: "84px" }}
                      onClick={() => {
                        if (!loading) {
                          form.submit();
                        }
                      }}
                    >
                      {loading ? <Spin /> : t("Submit")}
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {/* VIDEOS LIST COMPONENT */}{" "}
                  <Row gutter={25}>
                    {listOfVideo ? (
                      listOfVideo.length > 0 ? (
                        listOfVideo?.map((value, index) => {
                          return (
                            <>
                              <Col
                                key={index}
                                xxl={6}
                                xl={8}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={24}
                              >
                                {" "}
                                <Card
                                  key={index}
                                  onClick={() => {
                                    setUploadThumbnailImage(
                                      value.thumbnailImage
                                    );
                                    setShowPreviewButton(value.videoURL);
                                    form.setFieldsValue({
                                      title: value.title,
                                      discription: value.discription,
                                      videoURL: value.videoURL,
                                    });
                                    setMemberModalType("Edit");
                                    setSelectedData(value);
                                    setQuestionsData(value?.updatedQuestions);
                                    if (value?.updatedQuestions?.length > 0) {
                                      setShowQuestionComponent(true);
                                    }
                                  }}
                                  hoverable
                                  cover={
                                    <img
                                      alt="example"
                                      src={value.thumbnailImage}
                                    />
                                  }
                                >
                                  <Meta
                                    title={value.title}
                                    description={value.discription}
                                  />
                                </Card>
                              </Col>
                            </>
                          );
                        })
                      ) : (
                        <div className="no-data-component">
                          {" "}
                          <Empty />
                        </div>
                      )
                    ) : (
                      Array.from(Array(8).keys())
                        .map((name) => ({ name }))
                        .map((item, index) => {
                          return (
                            <Col
                              key={index}
                              xxl={6}
                              xl={8}
                              lg={12}
                              md={12}
                              sm={12}
                              className="course-list-skeleton"
                              xs={24}
                            >
                              <Card
                                cover={
                                  <Skeleton.Input
                                    key={index}
                                    active={true}
                                    size={"large"}
                                  />
                                }
                              >
                                <Meta title={""} description={""} />
                              </Card>
                            </Col>
                          );
                        })
                    )}
                  </Row>
                  <div className="tabelsPagenation">
                    <Pagination
                      total={countsOfVideosList.totalCount}
                      showSizeChanger
                      pageSizeOptions={[10, 20, 50, 100]}
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={onPageChange}
                    />
                  </div>
                </>
              )}
            </Card>

            {/* DEFINE COMPONENT FOR QUESTION CREATE & EDIT */}
            {showQuestionComponent ? (
              <Card
                extra={
                  <>
                    <button
                      className="add-btn"
                      onClick={() => {
                        let updateAnswer = addObjectInArray(questionsData, {
                          key: (questionsData?.length + 1).toString(),
                          time: "01",
                          label:
                            questionsData?.length > 0
                              ? t("This is question") +
                              " " +
                              `${questionsData?.length + 1}`
                              : t("This is question") + " 1",
                          correctAnswer: "1",
                          children: [
                            {
                              option:
                                questionsData?.length > 0
                                  ? `Option ${questionsData?.length + 1}`
                                  : "Option 1",
                              color: "#0b2322",
                              optionKey: "1",
                              navigateType: "none",
                              navigateValue: listOfCourseVideoList[0]?.value,
                            },
                          ],
                        });
                        setQuestionsData([...updateAnswer]);
                      }}
                    >
                      {t("Add")} <FeatherIcon icon="plus" size={18} />
                    </button>
                  </>
                }
                className={"mb-24 videoCreateCard"}
                bordered={false}
                title={t("Add Questions")}
              >
                <Collapse
                  expandIconPosition={"end"}
                  items={questionsData?.map(function (item, index) {
                    return {
                      ...item,
                      label: (
                        <>
                          <div className="question_time_div">
                            <input
                              className="question_input"
                              value={item.label}
                              onChange={(event) => {
                                let updateAnswer = findAndUpdateArray(
                                  questionsData,
                                  {
                                    key: item.key,
                                  },
                                  {
                                    label: event.target.value,
                                  }
                                );
                                setQuestionsData([...updateAnswer]);
                              }}
                            />
                            <label>
                              <TimePicker
                                onChange={(time, timeString) => {
                                  let updateAnswer = findAndUpdateArray(
                                    questionsData,
                                    {
                                      key: item.key,
                                    },
                                    {
                                      time: convertDurationtoSeconds(
                                        timeString
                                      ).toString(),
                                    }
                                  );
                                  setQuestionsData([...updateAnswer]);
                                }}
                                format={"HH:mm:ss"}
                                showNow={false}
                                disabledHours={() => {
                                  const hours = [];
                                  const currentHour = Number(
                                    convertSecondsToHours(
                                      selectedData?.videoLength
                                    )
                                  );
                                  for (let i = currentHour + 1; i <= 24; i++) {
                                    hours.push(i);
                                  }
                                  return hours;
                                }}
                                disabledMinutes={() => {
                                  const minutes = [];
                                  const currentMinute = Number(
                                    convertSecondsToMinutes(
                                      selectedData?.videoLength
                                    )
                                  );
                                  for (
                                    let i = currentMinute + 1;
                                    i <= 60;
                                    i++
                                  ) {
                                    minutes.push(i);
                                  }
                                  return minutes;
                                }}
                                disabledSeconds={() => {
                                  const seconds = [];
                                  const currentSeconds = Number(
                                    convertSecondsToSeconds(
                                      selectedData?.videoLength
                                    )
                                  );
                                  for (
                                    let i = currentSeconds + 1;
                                    i <= 60;
                                    i++
                                  ) {
                                    seconds.push(i);
                                  }
                                  return seconds;
                                }}
                                defaultValue={dayjs(
                                  convertSecondsToHoursMinuitesSeconds(
                                    item?.time
                                  ),
                                  "HH:mm:ss"
                                )}
                                value={dayjs(
                                  convertSecondsToHoursMinuitesSeconds(
                                    item?.time
                                  ),
                                  "HH:mm:ss"
                                )}
                                defaultOpenValue={dayjs(
                                  convertSecondsToHoursMinuitesSeconds(
                                    item?.time
                                  ),
                                  "HH:mm:ss"
                                )}
                                clearIcon={false}
                              />
                            </label>
                          </div>
                        </>
                      ),
                      children: (
                        <>
                          {item?.children?.map((opt, index) => {
                            return (
                              <>
                                <div key={index + 1}>
                                  <b>
                                    Option {index + 1} <p>|</p>{" "}
                                    {t("Correct Answer")} :
                                    <input
                                      className="options_ans_checkbox"
                                      type="checkbox"
                                      checked={
                                        item?.correctAnswer == opt?.optionKey
                                      }
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          let updateAnswer = findAndUpdateArray(
                                            questionsData,
                                            {
                                              key: item.key,
                                            },
                                            {
                                              correctAnswer: opt.optionKey,
                                            }
                                          );
                                          setQuestionsData([...updateAnswer]);
                                        }
                                      }}
                                    />
                                  </b>
                                  <div className={"options_div"}>
                                    <span className="options_option_span">
                                      <input
                                        onChange={(event) => {
                                          let updateAnswer =
                                            findAndUpdateOptionArray(
                                              questionsData,
                                              {
                                                key: item.key,
                                              },
                                              {
                                                optionKey: opt?.optionKey,
                                              },
                                              {
                                                option: event.target.value,
                                              }
                                            );
                                          setQuestionsData([...updateAnswer]);
                                        }}
                                        value={opt?.option}
                                      />
                                    </span>
                                    <span>
                                      <ColorPicker
                                        value={opt?.color}
                                        showText={(color) => {
                                          return (
                                            <span>{color.toHexString()}</span>
                                          );
                                        }}
                                        onChangeComplete={(color) => {
                                          let updateAnswer =
                                            findAndUpdateOptionArray(
                                              questionsData,
                                              {
                                                key: item.key,
                                              },
                                              {
                                                optionKey: opt?.optionKey,
                                              },
                                              {
                                                color: color.toHexString(),
                                              }
                                            );
                                          setQuestionsData([...updateAnswer]);
                                        }}
                                      />
                                      <FeatherIcon
                                        icon="trash"
                                        size={18}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          let updateAnswer =
                                            findAndDeleteOptionArray(
                                              questionsData,
                                              {
                                                key: item.key,
                                              },
                                              {
                                                optionKey: opt?.optionKey,
                                              }
                                            );
                                          setQuestionsData([...updateAnswer]);
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className={"options_div"}>
                                    <Select
                                      showSearch
                                      value={
                                        opt?.navigateType
                                          ? opt?.navigateType
                                          : "none"
                                      }
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      placeholder={t("select functionality")}
                                      style={{
                                        minHeight: "45px",
                                      }}
                                      onChange={(val) => {
                                        let updateAnswer =
                                          findAndUpdateOptionArray(
                                            questionsData,
                                            {
                                              key: item.key,
                                            },
                                            {
                                              optionKey: opt?.optionKey,
                                            },
                                            {
                                              navigateType: val,
                                              navigateValue:
                                                val == "redirectToVideo"
                                                  ? listOfCourseVideoList[0]
                                                    ?.value
                                                  : val == "showTempVideo"
                                                    ? listOfAllVideosList[0]
                                                      ?.value
                                                    : "",
                                            }
                                          );
                                        setQuestionsData([...updateAnswer]);
                                      }}
                                      options={[
                                        { label: t("None"), value: "none" },
                                        {
                                          label: t("Redirect To Video"),
                                          value: "redirectToVideo",
                                        },
                                        {
                                          label: t("Show Temporary Video"),
                                          value: "showTempVideo",
                                        },
                                      ]}
                                    />
                                    {opt?.navigateType == "redirectToVideo" ? (
                                      <Select
                                        showSearch
                                        value={
                                          opt?.navigateValue == ""
                                            ? listOfCourseVideoList[0]?.value
                                            : opt?.navigateValue
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        placeholder={t("select video")}
                                        style={{
                                          minHeight: "45px",
                                        }}
                                        onChange={(val) => {
                                          let updateAnswer =
                                            findAndUpdateOptionArray(
                                              questionsData,
                                              {
                                                key: item.key,
                                              },
                                              {
                                                optionKey: opt?.optionKey,
                                              },
                                              { navigateValue: val }
                                            );
                                          setQuestionsData([...updateAnswer]);
                                        }}
                                        options={listOfCourseVideoList}
                                      />
                                    ) : opt?.navigateType == "showTempVideo" ? (
                                      <Select
                                        showSearch
                                        value={
                                          opt?.navigateValue == ""
                                            ? listOfAllVideosList[0]?.value
                                            : opt?.navigateValue
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        placeholder={t("select video")}
                                        style={{
                                          minHeight: "45px",
                                        }}
                                        onChange={(val) => {
                                          let updateAnswer =
                                            findAndUpdateOptionArray(
                                              questionsData,
                                              {
                                                key: item.key,
                                              },
                                              {
                                                optionKey: opt?.optionKey,
                                              },
                                              { navigateValue: val }
                                            );
                                          setQuestionsData([...updateAnswer]);
                                        }}
                                        options={listOfAllVideosList}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ),
                      extra: (
                        <>
                          <FeatherIcon
                            icon="trash"
                            size={18}
                            onClick={(event) => {
                              event.stopPropagation();
                              confirm({
                                title: t("Delete question"),
                                className: "question_confimation_modal",
                                centered: true,
                                cancelText: t("No"),
                                okText: t("Yes"),
                                content: t(
                                  "Are you sure delete this question?"
                                ),
                                onOk() {
                                  let updateAnswer = deleteFromArray(
                                    questionsData,
                                    {
                                      key: item.key,
                                    }
                                  );
                                  setQuestionsData([...updateAnswer]);
                                },
                                onCancel() { },
                              });
                            }}
                          />
                          <FeatherIcon
                            icon="plus"
                            size={18}
                            onClick={(event) => {
                              event.stopPropagation();
                              let updateAnswer = findAndAddOptionArray(
                                questionsData,
                                {
                                  key: item.key,
                                },
                                {
                                  option: `Option ${item?.children?.length + 1
                                    }`,
                                  color: "#0b2322",
                                  optionKey: (
                                    item?.children?.length + 1
                                  ).toString(),
                                  navigateType: "none",
                                  navigateValue: "",
                                  navigateValue:
                                    listOfCourseVideoList[0]?.value,
                                }
                              );
                              setQuestionsData([...updateAnswer]);
                            }}
                          />
                        </>
                      ),
                    };
                  })}
                />
                <div className="question_buttons_div">
                  <button
                    className="add-btn"
                    style={{ marginTop: "14px", width: "84px" }}
                    onClick={() => {
                      if (!loading) {
                        form.submit();
                      }
                    }}
                  >
                    {loading ? <Spin /> : t("Submit")}
                  </button>
                </div>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AddVideo;
