import React from "react";
import { Layout, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

// DEFINE MAIN FUNCTION
const Footer = () => {
  // DEFINE ALL STATES
  const { Footer: AntFooter } = Layout;
  const { t } = useTranslation();

  // DEFINE MAIN FUNCTION RETURN
  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col span={24}>
          <div className="copyright">
            © {new Date().getFullYear()}
            <a className="font-weight-bold" target="_blank">
              {t("Document AI")}
            </a>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Footer;
