import React, { useEffect, useState } from "react";
import { Col, Pagination, Progress, Row, Table, Flex } from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sectionProgressList } from "../../../../../../../redux/user/learner/section/action";
import { statisticsDetailsList } from "../../../../../../../redux/user/learner/statistics/action";
const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const LearnedCourseSections = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const courseID = window.location.pathname.split("/").pop();
  const [percentQue, setPercentQue] = useState(0);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    setLoading(true);
    detalsList();
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfSection = useSelector((state) => state.learnerSection.list);
  let countsOfSection = useSelector((state) => state.learnerSection.listCounts);
  let listOfStatistics = useSelector((state) => state.statistic.details);
  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search) => {
    await dispatch(sectionProgressList(page, current, search, courseID));
    setLoading(false);
  };
  const detalsList = async () => {
    await dispatch(statisticsDetailsList(courseID));
  };
  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setLoading(true);
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h : ${minutes}m : ${seconds}s`;
  };
  // SECTION LIST TABLE COLUMNS DATA
  const sectionTableColumns = [
    {
      title: t("Title"),
      dataIndex: "title",
      width: "48%",
    },
    {
      title: t("Progress"),
      width: "40%",
      render: (icon, value) => {
        const roundedPercentage = Math.round(value.completedPercentage);
        return (
          <>
            <div className="percent-progress-animation percent-progress">
              <Progress className={styles.antProgress} percent={roundedPercentage} size="small" />
            </div>
          </>
        );
      },
    },
    {
      title: t("Time"),
      width: "40%",
      render: (icon, value) => {
        return (
          <>
            <div> {formatTime(Math.round(value.totalTimeSpend))}</div>
          </>
        );
      },
    },
    {
      title: t("Action"),
      width: "12%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(
                    `/company/workSpace/learned/${courseID}/${value._id}`
                  );
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={9} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={5} xl={5} lg={2} md={11} sm={8} xs={6}>
            {/*   <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.filterData}>
                <div className={styles.filterIcone}>
                  {" "}
                  <FeatherIcon icon="grid" fill="black" size={20} />
                </div>
                <div className={styles.filterMenu}>
                  <FeatherIcon icon="menu" fill="black" size={20} />
                </div>
              </div> 
            </div>*/}
          </Col>
          <Col
            xxl={9}
            xl={9}
            lg={9}
            md={11}
            sm={8}
            xs={6}
            className={styles.coursemainCard}
          >
            <div className={styles.courseCard}>
              <div>
                <div className={styles.courseCardTextDiv}>
                  <span>{t("Questions")}</span>
                </div>
                <div className={styles.queCardDescDiv}>
                  <i class="fa-solid fa-circle-question"></i>{" "}
                  {t("Total Questions")}:
                  <span> {listOfStatistics?.totalQuestions}</span>
                </div>
                <div className={styles.queCardDesDiv}>
                  <i class="fa-regular fa-circle-right"></i> {t("Answered")}:
                  <span> {listOfStatistics?.answeredQuestions}</span>
                </div>
                <div className={styles.queCardDeDiv}>
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  {t("Correct Answer")}:
                  <span> {listOfStatistics?.rightAnswer}</span>/{" "}
                  {listOfStatistics?.answeredQuestions}
                </div>
              </div>
              <div>
                <div className={styles.courseCardProgressDiv}>
                  <Flex gap="small" wrap>
                    <Progress
                      className={styles.antProgress}
                      type="circle"
                      percent={listOfStatistics?.correctionRatio}
                      strokeWidth={10}
                      strokeColor={twoColors}
                    />
                  </Flex>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.latestVideoContent} style={{ marginTop: "110px" }}>
        <div className="table-responsive">
          <Table
            columns={sectionTableColumns}
            dataSource={listOfSection}
            loading={loading}
            pagination={false}
            className="ant-border-space"
          />
        </div>
        <div className="tabelsPagenation" style={{ padding: "0" }}>
          <Pagination
            total={countsOfSection.totalCount}
            showSizeChanger
            pageSizeOptions={[10, 20, 50, 100]}
            defaultPageSize={pageSize}
            defaultCurrent={currentPage}
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default LearnedCourseSections;
