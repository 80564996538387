import axios from "axios";
import { getItem } from "../utility/localStorageControl";
import { API_ENDPOINT } from "./Constant";
import Cookies from "js-cookie";

const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
  languagecode: getItem("selectedLanguage"),
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}, loadingPercentage) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (loadingPercentage) {
          loadingPercentage(percentage);
        }
      },
    });
  }

  static put(path = "", data = {}, optionalHeader = {}, loadingPercentage) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (loadingPercentage) {
          loadingPercentage(percentage);
        }
      },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }
}

client.interceptors.request.use((config) => {
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    if (!response.data.error) {
      return response;
    } else if (response.data.error && response.data.statusCode === 200) {
      return response;
    }
  },
  (error) => {
    const { response } = error;
    const originalRequest = error.response;
    if (response) {
      if (response.status === 500 || response.status === 400) {
        return originalRequest;
      } else if (response.status === 401) {
        localStorage.clear();
        Cookies.remove("logedIn");
        window.location.reload();
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  }
);

export { DataService, API_ENDPOINT };
