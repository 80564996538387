import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Pagination, Popover, Row, Skeleton } from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { videoList } from "../../../../../../../redux/admin/videos/action";
import { setItem } from "../../../../../../../utility/localStorageControl";
import { startLearning } from "../../../../../../../redux/user/learner/course/action";

// DEFINE MAIN FUNCTION
const NotLearnedCourseVideos = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startInform, setStartInform] = useState(true);
  const sectionID = window.location.pathname.split("/").pop();
  const courseID =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 2
    ];

  // DEFINE USEEFFECT FOR INFRTOM START LEARNING POPOVER
  useEffect(() => {
    setStartInform(true);
    setTimeout(() => {
      setStartInform(false);
    }, 5000);
  }, []);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfVideo = useSelector((state) => state.video.list);
  let countsOfVideosList = useSelector((state) => state.video.listCounts);

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search) => {
    await dispatch(videoList(page, current, search, courseID, sectionID));
  };

  // DEFINE FUNCTION FOR START LEARNING API CALL
  const onStartLearning = async () => {
    let response = await dispatch(startLearning(courseID));
    if (response) {
      setItem("InfromStartLearning", true);
      navigate("/company/workSpace");
    }
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.filterData}>
                {/* <div className={styles.filterIcone}>
                  {" "}
                  <FeatherIcon icon="grid" fill="black" size={20} />
                </div>
                <div className={styles.filterMenu}>
                  <FeatherIcon icon="menu" fill="black" size={20} />
                </div> */}
              </div>
              <Popover
                open={startInform}
                title={t("Start learning now by clicking the button")}
              >
                <button
                  className="add-btn"
                  onClick={() => {
                    onStartLearning();
                  }}
                >
                  {t("Start Learning")}{" "}
                  <FeatherIcon icon="chevron-right" size={18} />
                </button>
              </Popover>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.latestVideoContent} style={{ marginTop: "30px" }}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfVideo ? (
            listOfVideo.length > 0 ? (
              listOfVideo?.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      {" "}
                      <Card
                        hoverable
                        cover={<img alt="example" src={value.thumbnailImage} />}
                      >
                        <Meta
                          title={value.title}
                          description={value.discription}
                        />
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                {" "}
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(8).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>
        <div className="tabelsPagenation" style={{ padding: "0" }}>
          <Pagination
            total={countsOfVideosList.totalCount}
            showSizeChanger
            pageSizeOptions={[10, 20, 50, 100]}
            defaultPageSize={pageSize}
            defaultCurrent={currentPage}
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default NotLearnedCourseVideos;
