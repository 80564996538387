import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Empty,
  Pagination,
  Progress,
  Row,
  Skeleton,
  message,
  Flex,
} from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import {
  removeItem,
  getItem,
  setItem,
} from "../../../../../../../utility/localStorageControl";
import {
  videoProgressList,
  videoWatchHistory,
  videoQuestionAnswer,
} from "../../../../../../../redux/user/learner/videos/action";
import {
  convertSecondsToHMS,
  findAndUpdateArray,
} from "../../../../../../../config/CommonFuntion";
import {
  sectionProgressDetails,
  sectionLearnerProgressList,
} from "../../../../../../../redux/user/learner/section/action";
import * as BABYLON from "babylonjs";
import Control from "../../../../../../../components/Controls/Control";
const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const LearnedCourseVideos = ({ width }) => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [videoSearchData, setVideoSearchData] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [tempVideoStatus, setTempVideoStatus] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [showQuestions, setShowQuestions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const sectionID = window.location.pathname.split("/").pop();
  const courseID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 2
    ];
  const canvasRef = useRef(null);
  const videoDomeRef = useRef(null);
  const sceneRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isAbcdData, setAbcdData] = useState([]);
  const [playStatus, setPlayStatus] = useState(false);
  const [fullScreenStatus, setFullScreenStatus] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const controlRef = useRef(null);
  let videoDome;

  // DEFINE ALL LISTS WITH REDUX
  let listOfVideo = useSelector((state) =>
    state.video.list ? state.video.list : state.learnerVideo.list
  );
  let countsOfVideosList = useSelector((state) =>
    state.video.listCounts
      ? state.video.listCounts
      : state.learnerVideo.listCounts
  );
  // DEFINE A USEEFFECT HOOK FOR SPACE KEY PRESS EVENT LISTENER
  useEffect(() => {
    staticsDetails();
    const abcdData = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode(65 + i)
    );
    setAbcdData(abcdData);
    const handleKeyDown = (event) => {
      if (event.keyCode === 32) {
        if (!tempVideoStatus) {
          handlePlayPauseClick();
        }
      }
      if (event.keyCode === 27) {
        event.preventDefault();
        setShowControls(false);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect will run only once when the component mounts
  const staticsDetails = async () => {
    await dispatch(sectionLearnerProgressList(sectionID));
  };
  let listStatisticsVideo = useSelector((state) => state.learnerSection.detail);

  // DEFINE FUNCTION FOR SELECT VIDEO
  const onSelectVideo = (value, queTime, status) => {
    setItem("selectVideo", value);
    setShowControls(true);
    setSelectedVideo(value);
    list("", "", "", false);
    setIsLoading(true);
    setCurrentTime(0); // Reset currentTime when loading a new video
    setVolume(1); // Reset volume when loading a new video
    setTimeout(() => {
      loadVideo(
        value?.videoURL,
        queTime ? queTime : value?.consumedTime,
        value?.questions,
        status ? status : false
      );
    }, 100);
    setShowQuestions(null);
  };

  // DEFINE FUNCTION FOR SELECT QUESTION
  const SelectQuestion = (consumedTime, tempVideoStatus) => {
    setShowQuestions(null);
    setShowControls(true);
    setItem("selectVideo", selectedVideo);
    setSelectedVideo(selectedVideo);
    list("", "", "", false);
    setIsLoading(true);
    setCurrentTime(0); // Reset currentTime when loading a new video
    setVolume(1); // Reset volume when loading a new video
    setTimeout(() => {
      loadVideo(
        tempVideoStatus ? consumedTime : selectedVideo?.videoURL,
        tempVideoStatus ? "0" : consumedTime + 1,
        tempVideoStatus ? [] : selectedVideo?.questions,
        tempVideoStatus
      );
    }, 100);
    setShowQuestions(null);
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h : ${minutes}m : ${seconds}s`;
  };
  // DEFINE THE FUNCTION FOR VIDEO FUNCTIONALITY, INCLUDING LOADING THE VIDEO
  const loadVideo = async (
    videoURL,
    consumedTime,
    questions,
    tempVideoStatus
  ) => {
    if (videoURL) {
      try {
        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        const scene = new BABYLON.Scene(engine);
        sceneRef.current = scene; // Storing scene reference
        const camera = new BABYLON.ArcRotateCamera(
          "Camera",
          -Math.PI / 4,
          Math.PI / 2,
          5,
          BABYLON.Vector3.Zero(),
          scene
        );
        camera.attachControl(canvas, true);
        camera.fov = 1.5;

        // Pause the previous video if it exists
        if (videoDomeRef.current) {
          const previousVideo = videoDomeRef.current.videoTexture.video;
          previousVideo.pause();
        }

        videoDome = new BABYLON.VideoDome(
          "videoDome",
          [videoURL],
          {
            resolution: 32,
            autoPlay: true,
            muted: false,
            loop: false,
          },
          scene
        );

        videoDomeRef.current = videoDome;

        const light = new BABYLON.HemisphericLight(
          "light",
          new BABYLON.Vector3(0, 1, 0),
          scene
        );

        videoDome.videoTexture.video.onloadedmetadata = () => {
          setVideoDuration(videoDome.videoTexture.video.duration);
        };

        videoDome.videoTexture.video.oncanplay = () => {
          setPlayStatus(true);
          setIsLoading(false); // Set loading state to false when video can start playing
        };

        videoDome.videoTexture.video.onwaiting = () => {
          setIsLoading(true); // Set loading state to true when video is waiting for more data
        };

        videoDome.videoTexture.video.onplaying = () => {
          setPlayStatus(true); // Set loading state to true when video is playing
          setIsLoading(false); // Set loading state to false when video starts playing
        };

        // videoDome.videoTexture.video.currentTime = tempVideoStatus
        //   ? "0"
        //   : consumedTime;
        videoDome.videoTexture.video.currentTime = tempVideoStatus
          ? consumedTime
          : "0";
        videoDome.videoTexture.video.ontimeupdate = () => {
          const currentTime = videoDome.videoTexture.video.currentTime;
          setCurrentTime(currentTime);

          let checkQuestionAvailable = questions?.find(
            (val) => val?.isAlreadyAnswered == false && val?.time < currentTime
          );
          if (checkQuestionAvailable) {
            setIsLoading(true);
            setPlayStatus(false);
            videoDome.videoTexture.video.pause(); // Pause the video
            setShowQuestions(checkQuestionAvailable);
          } else {
            let questionAvailableAtExactTime = questions?.find(
              (val) => Math.abs(currentTime - val.time) < 0.1
            );
            if (questionAvailableAtExactTime) {
              setIsLoading(true);
              setPlayStatus(false);
              videoDome.videoTexture.video.pause(); // Pause the video
              setShowQuestions(questionAvailableAtExactTime);
            }
            // questions.forEach((point) => {
            //   // Check if the current time is within a small range around the specified pause point
            //   if (Math.abs(currentTime - point.time) < 0.1) {
            //     setIsLoading(true);
            //     setPlayStatus(false);
            //     videoDome.videoTexture.video.pause(); // Pause the video
            //     setShowQuestions(point);
            //   }
            // });
          }
        };

        // Inside the useEffect hook for loading video
        videoDome.videoTexture.video.onended = async () => {
          if (tempVideoStatus) {
            setTempVideoStatus(false);
            onSelectVideo(selectedVideo);
          } else {
            const selectVideo = getItem("selectVideo");
            await dispatch(
              videoWatchHistory({
                course: courseID,
                courseSection: sectionID,
                courseVideo: selectVideo?._id,
                consumedTime: selectVideo?.videoLength,
              })
            );
            onSelectVideo(listOfVideo[videoIndex + 1]);
            setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
          }
        };

        engine.runRenderLoop(() => {
          scene.render();
        });

        window.addEventListener("resize", () => {
          engine.resize();
        });

        return () => {
          engine.dispose();
        };
      } catch (error) {
        console.log("error.message", error.message);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadVideo("");

    return () => {
      if (videoDomeRef.current) {
        const currentVideo = videoDomeRef.current.videoTexture.video;
        currentVideo.pause();
        setPlayStatus(false);
      }
    };
  }, [location]);
  // DEFINE FUNCTION FOR VIDEO VOLUME MUTE & UNMUTE CONTROL
  const muteUnmute = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.muted = !video.muted;
      if (video.muted) {
        setVolume(0);
      } else {
        setVolume(100);
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO CURRENT TIME CHANGE CONTROL
  const handleProgressBarClick = (value) => {
    if (!isLoading && !tempVideoStatus) {
      const newTime = (value / 100) * videoDuration;
      let checkQuestionAvailable = selectedVideo?.questions?.find(
        (val) => val?.isAlreadyAnswered == false && val?.time < newTime
      );
      if (checkQuestionAvailable) {
        setIsLoading(true);
        setPlayStatus(false);
        setShowQuestions(checkQuestionAvailable);
        setCurrentTime(checkQuestionAvailable?.time);
        if (videoDomeRef.current) {
          videoDomeRef.current.videoTexture.video.pause(); // Pause the video
          videoDomeRef.current.videoTexture.video.currentTime =
            checkQuestionAvailable?.time;
        }
      } else {
        setCurrentTime(newTime);
        if (videoDomeRef.current) {
          videoDomeRef.current.videoTexture.video.currentTime = newTime;
        }
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO VOLUME CHANGE CONTROL
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat((e / 100).toFixed(2));
    setVolume(newVolume);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.volume = newVolume;
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handleBackClick = async () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (isBack) {
      setPlayStatus(true);
      video.play();
    } else {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setPlayStatus(false);
      video.pause();
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handlePlayPauseClick = async () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (video.paused) {
      setPlayStatus(true);
      video.play();
    } else {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setPlayStatus(false);
      video.pause();
    }
  };

  // DEFINE FUNCTION FOR VIDEO PLAYING SPEED CONTROL
  const handlePlaybackSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.playbackRate = speed;
    }
  };

  // DEFINE FUNCTION FOR VIDEO FULL SCREEN CONTROL
  const toggleFullScreen = () => {
    if (!isLoading) {
      const canvas = canvasRef.current;
      if (!fullScreenStatus) {
        if (canvas.exitFullscreen) {
          canvas.exitFullscreen();
          setFullScreenStatus(false);
        } else if (canvas.mozCancelFullScreen) {
          canvas.mozCancelFullScreen();
          setFullScreenStatus(false);
        } else if (canvas.webkitExitFullscreen) {
          canvas.webkitExitFullscreen();
          setFullScreenStatus(false);
        } else if (canvas.msExitFullscreen) {
          canvas.msExitFullscreen();
          setFullScreenStatus(false);
        }
      } else {
        if (canvas.requestFullscreen) {
          canvas.requestFullscreen();
          setFullScreenStatus(true);
        } else if (canvas.mozRequestFullScreen) {
          canvas.mozRequestFullScreen();
          setFullScreenStatus(true);
        } else if (canvas.webkitRequestFullscreen) {
          canvas.webkitRequestFullscreen();
          setFullScreenStatus(true);
        } else if (canvas.msRequestFullscreen) {
          canvas.msRequestFullscreen();
          setFullScreenStatus(true);
        }
      }
    }
  };

  // DEFINE USEEFFECT FOR SECTION DETAILS API CALL FUNCTION
  useEffect(() => {
    details();
  }, []);

  // DEFINE FUNCTION FOR SECTION DETAILS API CALL
  const details = async () => {
    await dispatch(sectionProgressDetails(sectionID));
  };

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData, true);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, videoSearchData, true);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [videoSearchData]);

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search, status) => {
    await dispatch(
      videoProgressList(page, current, search, courseID, sectionID, status)
    );
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData, true);
  };

  // DEFINE FUNTION FOR BACK VIDEO
  const handleBack = async () => {
    setIsBack(false);
    handleBackClick();
    let stop_time = Number(currentTime.toString().split(".")[0]);
    await dispatch(
      videoWatchHistory({
        course: courseID,
        courseSection: sectionID,
        courseVideo: selectedVideo?._id,
        consumedTime: stop_time.toString(),
      })
    );
    removeItem("selectVideo");
    setSelectedVideo(null);
    list(currentPage, pageSize, videoSearchData, true);
  };

  // DEFINE FUNCTION FOR PREVIOUS EPISODE
  const previousEpisode = () => {
    if (videoIndex !== 0) {
      setVideoIndex(
        (prevIndex) => (prevIndex - 1 + listOfVideo.length) % listOfVideo.length
      );
      onSelectVideo(listOfVideo[videoIndex - 1]);
    }
  };

  // DEFINE FUNCTION FOR NEXT EPISODE
  const nextEpisode = () => {
    if (videoIndex < 9) {
      setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
      onSelectVideo(listOfVideo[videoIndex + 1]);
    }
  };

  // Function to handle restarting the video
  const restartVideo = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime = 0;
      setCurrentTime(0);
      video.play();
    }
  };

  // DEFINE FUNCTION FOR REWIND 10 SECOND HANDLER
  const rewindHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime -= 10;
      // setCurrentTime(video.currentTime);
    }
  };

  // DEFINE FUNCTION FOR FOWARD 10 SECOND HANDLER
  const fastFowardHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime += 10;
      // setCurrentTime(video.currentTime);
    }
  };

  // DEFINE FUNCTION FOR SELECT QUESTION ON OPTIONS
  const onSelectQuestionOptions = async (_id, value, question) => {
    if (!question.isAlreadyAnswered) {
      videoQuestionAnswer({
        courseVideo: _id,
        question: question?.question,
        givenAnswer: value?.option,
      });
    }

    let updateAnswer = findAndUpdateArray(
      selectedVideo?.questions,
      {
        question: question?.question,
      },
      {
        isAlreadyAnswered: true,
      }
    );

    setSelectedOption(null);
    setSelectedVideo({ ...selectedVideo, questions: updateAnswer });

    if (question?.correctAnswer == value?.optionKey) {
      message.success(t("Correct answer selected"));
      let audio = new Audio(
        require("../../../../../../../assets/images/audio/right-answer.mp3")
      );
      audio.play();
    } else {
      let audio = new Audio(
        require("../../../../../../../assets/images/audio/wrong-answer.mp3")
      );
      audio.play();
      message.error(t("Incorrect answer selected"));
    }

    if (value?.navigateType == "redirectToVideo") {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString() + 1,
        })
      );
      let findVideo = listOfVideo.find(
        (val) => val?._id == value?.navigateValue
      );
      if (findVideo) {
        onSelectVideo(findVideo);
      }
    } else if (value?.navigateType == "showTempVideo") {
      let stop_time = Number(currentTime.toString().split(".")[0]) + 1;
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setTempVideoStatus(true);
      SelectQuestion(value?.navigateValue, true);
    } else {
      // // SelectQuestion(Number(showQuestions.time), false);
      // let stop_time = Number(currentTime.toString().split(".")[0]) + 1;
      // await dispatch(
      //   videoWatchHistory({
      //     course: courseID,
      //     courseSection: sectionID,
      //     courseVideo: selectedVideo?._id,
      //     consumedTime: stop_time.toString(),
      //   })
      // );
      setTempVideoStatus(true);
      SelectQuestion(value?.navigateValue, true);
      const video = videoDomeRef.current.videoTexture.video;
      if (video.paused) {
        setPlayStatus(true);
        video.play();
      }
    }
  };

  // DEFINE FUNCTION FOR SHOW VIDEO CONTROLS SHOW & HIDE
  const showControlsFun = () => {
    setShowControls(!showControls);
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {selectedVideo ? (
        <>
          <div className={styles.videoComponent}>
            <div className="menu__btn_video_container">
              {showQuestions == null ? (
                <FeatherIcon
                  icon={showControls ? "x-circle" : "menu"}
                  style={{ color: "white" }}
                  size={25}
                  onClick={() => {
                    if (!isLoading) {
                      setShowControls(!showControls);
                    }
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div className={styles.videoSection}>
              {showQuestions ? (
                <div
                  style={{ flex: 1, aspectRatio: "1225 / 593", minWidth: 0 }}
                  className={styles.questionDiv}
                >
                  <span>{showQuestions.question}</span>
                  <div
                    style={{
                      display: "block",
                      maxHeight: "250px",
                      maxWidth: "530px",
                      overflowY: "auto",
                    }}
                    id={styles.customScrollbar}
                  >
                    {showQuestions.options.map((value, index) => {
                      return (
                        <div className={styles.optionContainer} key={index}>
                          <div
                            className={styles.abcdOption}
                            style={{
                              color: value.color,
                              boxShadow: "-3px 5px",
                            }}
                          >
                            {selectedOption?.optionKey == value?.optionKey ? (
                              <FeatherIcon icon="check" size={20} />
                            ) : (
                              isAbcdData[index]
                            )}
                          </div>
                          <label
                            onClick={() => {
                              setSelectedOption(value);
                            }}
                            style={{ backgroundColor: value.color }}
                          >
                            {value.option}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <div className={styles.optionContainer}>
                    <label
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        marginTop: "10px",
                        opacity: selectedOption ? "" : "50%",
                      }}
                      onClick={() => {
                        if (selectedOption) {
                          onSelectQuestionOptions(
                            selectedVideo?._id,
                            selectedOption,
                            showQuestions
                          );
                        }
                      }}
                    >
                      Submit
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={styles.video_section_controls}
                style={{
                  position: showQuestions == null ? "relative" : "static",
                }}
              >
                <canvas ref={canvasRef} id="renderCanvas" />
                {showControls && showQuestions == null ? (
                  <Control
                    playing={playStatus}
                    onPlayPause={handlePlayPauseClick}
                    controlRef={controlRef}
                    onMute={muteUnmute}
                    muted={volume == 0 ? true : false}
                    className="player"
                    volume={volume}
                    OnvolumeChangeHandler={handleVolumeChange}
                    onRewind={rewindHandler}
                    onForward={fastFowardHandler}
                    fullScreen={fullScreenStatus}
                    toggleFullScreen={toggleFullScreen}
                    playbackRate={handlePlaybackSpeedChange}
                    speed={playbackSpeed}
                    totalTime={videoDuration}
                    handleSliderChange={handleProgressBarClick}
                    playedSeconds={currentTime}
                    loading={isLoading}
                    qualityOptions={[{ tag: "HD" }, { tag: "Normal" }]}
                    previousEpisode={previousEpisode}
                    nextEpisode={nextEpisode}
                    handleBack={handleBack}
                    favorite={true}
                    onRestart={restartVideo}
                    width={width}
                    showControlsFun={showControlsFun}
                    showControls={showControls}
                    tempVideoStatus={tempVideoStatus}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className={styles.videoDetails}>
                <div className={styles.detailsLabel}>
                  {t("About this course")}
                </div>
                <section>
                  <label>{t("Title")}</label>
                  <span>{selectedVideo?.title}</span>
                </section>
                <section>
                  <label>{t("Description")}</label>
                  <span>{selectedVideo?.discription}</span>
                </section>
                <section>
                  <label>{t("Video Duration")}</label>
                  <span>{convertSecondsToHMS(selectedVideo?.videoLength)}</span>
                </section>
                {/* <section>
                  <label>{t("Total Videos")}</label>
                  <span>{countsOfVideosList?.totalCount}</span>
                </section> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.filterContent}>
            <Row gutter={12}>
              <Col xxl={9} xl={9} lg={10} md={10} sm={16} xs={18}>
                <div className={styles.searchData}>
                  <span>
                    <FeatherIcon icon="search" size={24} />
                    <input
                      value={searchData}
                      onChange={(event) => {
                        setSearchData(event.target.value);
                      }}
                      placeholder={t("Search for a workspace...")}
                    />
                    {searchData == "" ? (
                      ""
                    ) : (
                      <label>
                        <FeatherIcon
                          onClick={() => {
                            setSearchData("");
                          }}
                          icon="x"
                          size={22}
                        />
                      </label>
                    )}
                  </span>
                </div>
              </Col>
              <Col xxl={5} xl={5} lg={2} md={0} sm={8} xs={6}></Col>
              <Col xxl={9} xl={9} lg={12} md={14} sm={8} xs={6}>
                <div className={styles.courseCard}>
                  <div>
                    <div className={styles.courseCardTextDiv}>
                      <span>{t("Questions")}</span>
                    </div>
                    <div className={styles.queCardDescDiv}>
                      <i class="fa-solid fa-circle-question"></i>{" "}
                      {t("Total Questions ")}:
                      <span> {listStatisticsVideo?.totalQuestions}</span>
                    </div>
                    <div className={styles.queCardDesDiv}>
                      <i class="fa-regular fa-circle-right"></i> {t("Answered")}
                      :<span> {listStatisticsVideo?.answeredQuestions}</span>
                    </div>
                    <div className={styles.queCardDeDiv}>
                      <i class="fa-regular fa-circle-check"></i>{" "}
                      {t("Correct Answer")}:
                      <span> {listStatisticsVideo?.rightAnswer}</span>/{" "}
                      {listStatisticsVideo?.answeredQuestions}
                    </div>
                  </div>
                  <div>
                    <div className={styles.courseCardProgressDiv}>
                      <Flex gap="small" wrap>
                        <Progress
                          className={styles.antProgress}
                          type="circle"
                          percent={listStatisticsVideo?.correctionRatio}
                          strokeWidth={10}
                          strokeColor={twoColors}
                        />
                      </Flex>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={styles.filterData}>
                    <div className={styles.filterIcone}>
                      {" "}
                      <FeatherIcon icon="grid" fill="black" size={20} />
                    </div>
                    <div className={styles.filterMenu}>
                      <FeatherIcon icon="menu" fill="black" size={20} />
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>

          <div
            className={styles.latestVideoContent}
            style={{ marginTop: "140px" }}
          >
            <Row gutter={25} className="circlebox tablespace mb-24 coursesCard">
              {listOfVideo ? (
                listOfVideo.length > 0 ? (
                  listOfVideo.map((value, index) => (
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Card
                        onClick={() => {
                          setVideoIndex(
                            listOfVideo.findIndex(
                              (x) => x.videoURL === value.videoURL
                            )
                          );
                          onSelectVideo(value);
                        }}
                        hoverable
                        cover={<img alt="example" src={value.thumbnailImage} />}
                      >
                        <Meta
                          title={value.title}
                          description={value.description}
                        />
                        <Progress
                          className={styles.antProgress}
                          percent={
                            value.completedPercentage
                              ? Math.round(value.completedPercentage)
                              : 0
                          }
                          size="small"
                        />
                        <div className={styles.time_card}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span>
                            {formatTime(Math.round(value.consumedTime))}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div className="no-data-component">
                    <Empty />
                  </div>
                )
              ) : (
                Array.from({ length: 6 }).map((_, index) => (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card cover={<Skeleton.Input active size="large" />}>
                      <Meta title="" description="" />
                    </Card>
                  </Col>
                ))
              )}
            </Row>

            <div className="tabelsPagenation" style={{ padding: "0" }}>
              <Pagination
                total={countsOfVideosList.totalCount}
                showSizeChanger
                pageSizeOptions={[10, 20, 50, 100]}
                defaultPageSize={pageSize}
                defaultCurrent={currentPage}
                onChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default LearnedCourseVideos;
