import React, { useEffect } from "react";
import styles from "./style.module.css";
import Aos from "aos";
const CompanyTraningProcess = () => {
  // DEFINE USEEFFECT
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const array = [
    {
      name: "Section 1 - [Section name]",
      modules: [
        {
          data: "100%",
          name: "Module 1",
        },
        {
          data: "100%",
          name: "Module 2",
        },
        {
          data: "90%",
          name: "Module 3",
        },
      ],
    },
    {
      name: "Section 2 - [Section name]",
      modules: [
        {
          data: "100%",
          name: "Module 1",
        },
        {
          data: "100%",
          name: "Module 2",
        },
        {
          data: "30%",
          name: "Module 3",
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.headerComponent} data-aos="fade-right">
        <span>[Learner's Name]</span> <hr data-aos="fade-right" />
      </div>

      <div className={styles.sectionsComponent}>
        {array.map((value, index) => {
          return (
            <>
              <span
                className={styles.sectionName}
                key={index}
                data-aos="fade-right"
              >
                {value.name}
              </span>
              <div
                key={index}
                className={styles.moduleSection}
                data-aos="fade-left"
              >
                {value.modules.map((module, index) => {
                  return (
                    <>
                      <div className={styles.moduleComponent} key={index}>
                        <span className={styles.moduleName}>{module.name}</span>
                        {module.data == "100%" ? (
                          <>
                            <div
                              style={{
                                backgroundColor: "white",
                              }}
                              className={styles.moduleDataContent}
                            >
                              <span
                                style={{
                                  backgroundColor: "#25AE88",
                                }}
                                className={styles.moduleData}
                              >
                                <label>{module.data}</label>
                                <label>
                                  <label
                                    style={{
                                      backgroundColor: "#25AE88",
                                      width: module.data,
                                    }}
                                  ></label>
                                </label>
                              </span>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#DADDE1",
                            }}
                            className={styles.moduleDataContent}
                          >
                            <span className={styles.moduleMinData}>
                              <label
                                style={{
                                  width: module.data,
                                }}
                              >
                                {module.data}
                              </label>
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CompanyTraningProcess;
