import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// LOGIN API CALL
export const courseList = (page, current, search) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.course.list}?page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.course.list,
          list: response.data.data,
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const courseAssignedList = (page, current, search, organizationId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.course.assignedList}?page=${page}&limit=${current}${
          search == "" ? "" : "&search=" + search
        }${organizationId == "" ? "" : "&organizationId=" + organizationId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.course.assignedList,
          assignedList: response.data.data,
          assignedListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const courseUnAssignedList = (page, current, search, organizationId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.admin.course.unAssignedList}${
          page == "" ? "" : "?page=" + page
        }${current == "" ? "" : "&limit=" + current}${
          search == "" ? "" : "&search=" + search
        }${organizationId == "" ? "" : "?organizationId=" + organizationId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.admin.course.unAssignedList,
          unAssignedList: response.data.data.map(function (item) {
            return {
              label: `${item.title} ${
                item?.note && item?.note !== "" ? `- ${item?.note}` : ""
              }`,
              value: item._id,
            };
          }),
          unAssignedListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const courseCreate = (payloads, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.admin.course.create,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const courseDelete = (payloads) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.admin.course.delete + payloads
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const courseEdit = (payloads, id, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.course.edit + id,
        payloads,
        {
          "Content-Type": "multipart/form-data",
        },
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const coursesDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.course.details + id);
      if (response.data.status) {
        dispatch({
          type: API.admin.course.edit,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const assignOrganizationToCourse = (id, payload, loadingPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.course.assign + id,
        payload,
        {},
        loadingPercentage
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const unAssignOrganizationToCourse = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.admin.course.unAssign,
        payload
      );
      if (response.data.status) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
