import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import PublicRoutes from "./routes/publicRoutes/PublicRoutes";
import UserLayout from "./layout/user/UserLayout";
import AdminLayout from "./layout/admin/AdminLayout";
import { getItem, setItem } from "./utility/localStorageControl";

// DEFINE MAIN FUNCTION
function App() {
  let authDetails = useSelector((state) => state.auth);
  if (getItem("selectedLanguage")) {
    setItem("selectedLanguage", "fr");
  }
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <BrowserRouter>
        <Suspense>
          {authDetails.login ? (
            authDetails.userDetails.role == "admin" ? (
              <AdminLayout />
            ) : (
              <UserLayout role={authDetails.userDetails.role} />
            )
          ) : (
            <PublicRoutes />
          )}
        </Suspense>
      </BrowserRouter>
    </>
  );
}

// DEFINE MAIN FUNCTION EXPORT
export default App;
